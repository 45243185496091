/* eslint-disable */
export default {
    detectEngine: function() {
        const ua = navigator.userAgent;
        if (ua.indexOf('Edge') !== -1) {
            return 'Edge';
        } else {
            if (ua.indexOf('AppleWebKit') !== -1) {
                return 'WebKit';
            } else {
                if (ua.indexOf('Trident') !== -1) {
                    return 'Trident';
                } else {
                    if (ua.indexOf('Gecko') !== -1) {
                        return 'Gecko';
                    }
                }
            }
        }
        return undefined;
    },
    isMob: function() {
        const os = this.detectOs();
        return os === 'iOs' || os === 'Android';
    },
    detectOs: function() {
        var ua;
        ua = navigator.appVersion;
        if (ua.indexOf('iPhone') !== -1 || ua.indexOf('iPad') !== -1) {
            return 'iOs';
        }
        if (ua.indexOf('Android') !== -1) {
            return 'Android';
        }
        if (ua.indexOf('Win') !== -1) {
            return 'Windows';
        }
        if (ua.indexOf('Mac') !== -1) {
            return 'Mac';
        }
        if (ua.indexOf('X11') !== -1) {
            return 'Linux';
        }

        return 'Unknown';
    }
};
