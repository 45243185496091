import Vue from 'vue';
import _ from 'lodash';
import i18n from './i18n';
import store from './store';
import App from './App.vue';
import router from './router';
import '@/assets/css/base-styles.css';
// import * as Sentry from '@sentry/vue';
import Toast from 'vue-toastification';
import '@/assets/css/bootstrap-grid.css';
import 'vue-toastification/dist/index.css';
// import { BrowserTracing } from '@sentry/tracing';
import { InlineSvgPlugin } from 'vue-inline-svg';
import VueYandexMetrika from 'vue-yandex-metrika';
import VueMeta from 'vue-meta';

Vue.use(Toast);
Vue.use(VueMeta);
Vue.set(Vue.prototype, '_', _);
Vue.config.productionTip = false;

Vue.use(InlineSvgPlugin);

if (store._actions && store._actions.INIT) {
    store.dispatch('INIT');
}

Vue.use(VueYandexMetrika, {
    id: 90120084,
    router: router,
    env: process.env.NODE_ENV,
    
    // other options
    clickmap: true,
    webvisor: true,
    trackLinks: true,
    accurateTrackBounce: true
});

// if(process.env.NODE_ENV === 'production') {
//     Sentry.init({
//         Vue,
//         dsn: 'https://feac997c8a8b4ad7879f261d253546ac@o1403333.ingest.sentry.io/6736121',
//         integrations: [
//             new BrowserTracing({
//                 routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//                 tracingOrigins: ['slide-lab.ru', /^\//]
//             })
//         ],
//         // Set tracesSampleRate to 1.0 to capture 100%
//         // of transactions for performance monitoring.
//         // We recommend adjusting this value in production
//         tracesSampleRate: 1.0
//     });
// }

export const EventBus = new Vue();

window.App = new Vue({
    i18n,
    store,
    router,
    render: h => h(App),
    data: {
        ukey: process.env.VUE_APP_UKEY,
        gkey: process.env.VUE_APP_GKEY,
        baseUrl: process.env.VUE_APP_BASE_URL,
        storageUrl: process.env.VUE_APP_STORAGE_URL
    },
    methods: {
        // ToDo: прикрутить настоящий App.reachGoal
        reachGoal (goal) {
            console.log(goal);
        }
    }
}).$mount('#app');
