/* eslint-disable */

export default {
  'vueObj': {},
  'fill-on': true,
  'background-color': {
    'palette': 'mut1l50',
    'own': false
  },
  'background-opacity': 1,
  'stroke-on': false,
  'stroke-color': {
    'palette': 'acc1l50',
    'own': false
  },
  'stroke-width': 1,
  'stroke-type': 'solid',
  'corner-radius': [0, 0, 0, 0],
  'color-mode': 'solid',
  'background-mode': 'color',
  'gradient': {
    'type': 'linear',
    'angle': 0,
    'center': [50, 50],
    'points': [
      {
        'color': {
          'own': [255,
            255,
            255,
            1],
          'palette': false
        },
        'position': 0
      },
      {
        'color': {
          'own': [0,
            0,
            0,
            1],
          'palette': false
        },
        'position': 100
      }
    ]
  },
  'stroke-cap': 'round',
  'stroke-dash': [0, 0],
  'adjust-to-text': false,
  'font-family': 'Roboto',
  'background-image': '',
  'image-opacity': 1,
  'image-fill-mode': 'cover',
  'image-scale': 1,
  'arrows': {
    'start': 'none',
    'end': 'none'
  },
  'top-offset': 50,
  'parallelogram-angle': 30,
  'chevron-angle': -30,
  'chevron-first': false,
  'arrow-angle': 45,
  'arrow-direction': 'east',
  'arrow-thickness': 30,
  'lock-aspect': false,
  'opacity': 1,
  'image-custom-scale': 1,
  'image-shift-x': 0,
  'image-shift-y': 0
};
