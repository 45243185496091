import JSZip from 'jszip';
import { apiGet, apiPost, customApiRequest } from '@/models/Api';

export default {
    namespaced: true,
    state: {
        page: 1,
        count: 0,
        search: '',
        presentations: [],
        previewData: {
            presentation: null,
            allSlidesURL: null,
            firstSlideURL: null,
            firstSlide: null,
            allSlides: []
        }
    },
    actions: {
        async getAllSlides ({ state, commit }) {
            const res = await customApiRequest('get', state.previewData.allSlidesURL);
        
            if (res) {
                const zip = await JSZip.loadAsync(res, { base64: true });
                const str = await zip.file(Object.keys(zip.files)[0]).async('string');
                const presentation = JSON.parse(str);
            
                const statePresentation = state.previewData?.presentation || {};
            
                commit('setPreviewData', {
                    allSlides: presentation?.slides || [],
                    firstSlide: presentation?.slides[0] || null,
                    presentation: { ...statePresentation, ...presentation }
                });
            }
        },
        async searchPresentations ({ state, commit }) {
            if (state.page === 1) {
                commit('setCount', 0);
                commit('setPresentations', []);
            }
            
            const res = await apiGet(`/api/v1/presentation-search`, {
                page: state.page,
                search: state.search
            });
            
            if (res && res.count && res.data) {
                commit('setCount', res.count);
                commit('setPresentations', (state.page === 1 ? res.data : [...state.presentations, ...res.data]));
                
                commit('setPage', ++state.page);
            }
        },
        async getPresentationById ({ state, commit }, id) {
            if (!state.previewData.allSlidesURL) {
                const content = await apiGet('/api/v1/share/content', { id: id }, false);
                
                const storageUrl = process.env.VUE_APP_STORAGE_URL;
                await commit('setPreviewData', {
                    allSlidesURL: `${storageUrl}/${content.all}`,
                    presentation: {
                        name: content.name,
                        textContent: content.text_content
                    }
                });
            }
            
            const res = await customApiRequest('get', state.previewData.allSlidesURL);
            
            if (res) {
                const zip = await JSZip.loadAsync(res, { base64: true });
                const json = await zip.file(Object.keys(zip.files)[0]).async('string');
                const presentation = JSON.parse(json);
                
                commit('setPreviewData', {
                    allSlides: presentation?.slides || [],
                    firstSlide: presentation?.slides[0] || null,
                    presentation: { ...state.previewData.presentation, ...presentation }
                });
                
                commit('presentation/setPresentationData', {key: 'theme', value: presentation.meta}, { root: true });
            } else {
                throw new Error('presentationNotFound');
            }
        },
        async forkCurrentPresentation ({ state, commit }, id) {
            const res = await apiPost(`/api/v1/presentation/${id}/clone`);
            
            if (res) {
                return true;
            }
        },
    },
    mutations: {
        setPage: (state, page) => state.page = page,
        setCount: (state, count) => state.count = count,
        setSearch: (state, search) => state.search = search,
        setPresentations: (state, presentations) => state.presentations = presentations,
        setPreviewData: (state, data) => {
            state.previewData = {
                presentation: data?.presentation || null,
                allSlidesURL: data?.allSlidesURL || null,
                firstSlideURL: data?.firstSlideURL || null,
                firstSlide: data?.firstSlide || null,
                allSlides: data?.allSlides || []
            };
        }
    }
};
