// @flow
import axios from 'axios';
import type { Method } from 'axios';
import { UserSingleton } from '@/models/UserSingleton';

const { VUE_APP_BASE_URL } = process.env;
if (!VUE_APP_BASE_URL) throw new Error('Base url missing');

const BASE_URL: string = VUE_APP_BASE_URL;

/**
 * Check server accessibility...
 * @param calback
 * @returns {Promise<*>}
 */
// Вернуть по надобности...
// async function checkConnection(calback: ?Function): Promise<boolean> {
//     return await axios.get(`${BASE_URL}/health`, { timeout: 8000 })
//         .then(response => {
//             return !!response.data;
//         }).catch(() => {
//             handleErrors({ message: 'Server not response' }, calback);
//
//             return false;
//         });
// }

/**
 * Add headers to request + add auth headers if user has token...
 * @returns {Promise<Object>}
 */
async function getHeaders (): Promise<Object> {
    const token = UserSingleton.getToken();
    const header: Object = {};
    
    if (token) {
        header.Authorization = `Bearer ${token}`;
    }
    
    return header;
}

/**
 * Errors handler...
 * @param data
 * @param callback
 */
// ToDo: Прикрутить обработку ошибок после перехода на нормальное API и решения остальных вопросов...
// function handleErrors(data: Object, callback: ?Function): void {
//     const buttons = [{
//         text: 'OK', onPress: () => {
//             return false;
//         }
//     }];
//
//     if(callback) {
//         buttons.push({ text: 'Try Again', onPress: () => callback() });
//     }
//
//     // setTimeout(() => Alert.alert(i18.error, data.message, buttons, { cancelable: false }), 300);
// }

/**
 * Post запрос с авторизацией и обработкой ответа и ошибок...
 * @param fetchUrl
 * @param params
 * @param checkStatus
 * @returns {Promise<boolean|*>}
 */
export const apiPost = async (fetchUrl: string, params: Object = {}, checkStatus: boolean = true): Object | string | Boolean => {
    // console.trace(`GET: ${fetchUrl}`);
    return apiRequest('POST', fetchUrl, params, checkStatus);
};

/**
 * Put запрос с авторизацией и обработкой ответа и ошибок...
 * @param fetchUrl
 * @param params
 * @param checkStatus
 * @returns {Promise<boolean|*>}
 */
export const apiPut = async (fetchUrl: string, params: Object = {}, checkStatus: boolean = true): Object | string | Boolean => {
    return apiRequest('PUT', fetchUrl, params, checkStatus);
};

/**
 * Delete запрос с авторизацией и обработкой ответа и ошибок...
 * @param fetchUrl
 * @param params
 * @param checkStatus
 * @returns {Promise<boolean|*>}
 */
export const apiDelete = async (fetchUrl: string, params: Object = {}, checkStatus: boolean = true): Object | string | Boolean => {
    return apiRequest('DELETE', fetchUrl, params, checkStatus);
};

/**
 * Get запрос с авторизацией и обработкой ответа и ошибок...
 * @param fetchUrl
 * @param params
 * @param checkStatus
 * @returns {Promise<boolean|*>}
 */
export const apiGet = async (fetchUrl: string, params: Object = {}, checkStatus: boolean = true): Object | string | Boolean => {
    return apiRequest('GET', fetchUrl, params, checkStatus);
};

/**
 * @param method
 * @param fetchUrl
 * @param data
 * @param checkStatus
 * @param timeout
 * @returns {Promise<boolean|*>}
 */
const apiRequest = async (method: Method, fetchUrl: string, data: Object = {}, checkStatus: boolean = true, timeout: number = 60000): Object | string | Boolean => {
    const headers = await getHeaders();
    const url = `${BASE_URL}${fetchUrl}`;
    const axiosParams = {
        method,
        url,
        headers,
        data: {},
        params: {},
        timeout
    };
    
    if (method === 'GET') {
        axiosParams.params = data;
    } else {
        axiosParams.data = data;
    }
    
    const result = await axios(axiosParams)
        .then(response => response.data)
        .catch(error => {
            if (process.env.NODE_ENV) {
                console.log(error);
            }
            
            if (!checkStatus) {
                return error;
            }
            
            if (error?.response?.status === 401) {
                if (!url.includes('logout')) {
                    window.App.$store.dispatch('logout', '/login');
                }
            } else {
                const statusText = error?.response?.data?.error || 'internalError';
                
                window.App.$toast.error(
                    window.App.$t(statusText),
                    {
                        position: 'bottom-right',
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: false,
                        showCloseButtonOnHover: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false
                    });
            }
            
            return { status: 'fail', message: 'Ошибка, попробуйте позже' };
        });
    
    if (result.constructor.name === 'AxiosError') {
        window.App.$emit('error', {
            code: result?.response?.status || 500, // eslint-disable-line
            message: result?.response?.statusText || 'ServerError' // eslint-disable-line
        });
        return;
    }
    
    if (!checkStatus || (result && typeof result.status === 'undefined')) {
        return result;
    }
    
    if (result?.status === 1) {
        return result.data || true;
    } else if (result?.status === 429) {
        console.log('logout');
    } else {
        return false;
    }
};

/**
 * Кастомный запрос с обработкой ответа и ошибок...
 * @param method
 * @param url
 * @param params
 * @param auth
 * @param headers
 * @param timeout
 * @returns {Promise<*>}
 */
export const customApiRequest = async (method: Method, url: string, params: Object = {}, auth: boolean = false, headers: Object = {}, timeout: number = 60000): Object | string | Boolean => {
    if (auth) {
        const authHeaders = await getHeaders();
        headers = { ...headers, ...authHeaders };
    }
    
    return await axios({ method, url, params, headers, timeout, mode: 'no-cors' })
        .then(response => {
            return response.data;
        }).catch((error) => {
            if (process.env.NODE_ENV) {
                console.log(error);
            }
            
            return false;
        });
};
