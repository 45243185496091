import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const locale = localStorage.getItem('locale') || ((navigator?.language || navigator?.userLanguage) === 'ru' ? 'ru' : 'en');

export default new VueI18n({
    locale,
    fallbackLocale: 'en',
    messages: {
        en: {
            'go_home': 'To main',
            homeTitle: 'SlideLab: Presentation Software | Create presentation online',
            homeDescription: 'Create your presentation online, share it via the link link, give access to everyone or download it from our editor',
            docsTitle: 'SlideLab: Presentation Software | Documentation',
            policyTitle: 'SlideLab: Presentation Software | Privacy policy',
            loginTitle: 'SlideLab: Presentation Software | Sign In',
            accountTitle: 'SlideLab: Presentation Software | Account',
            managerTitle: 'SlideLab: Presentation Software | Manager',
            editorTitle: 'SlideLab: Presentation Software | Editor',
            shareTitle: 'SlideLab: Presentation Software | Share Presentation',
            presentationSearchTitle: 'SlideLab: Presentation Search | Share Presentation',
            presentationPreviewTitle: 'SlideLab: Presentation Preview | Share Presentation',
            presentationSearchDescription: 'Download the finished presentation. Editor and bank of ready-made presentations SlideLab.',
            presentationPreviewDescription: 'Download the finished presentation. Editor and bank of ready-made presentations SlideLab.',
            'Doubleclick to add slide title': 'Doubleclick to add slide title',
            'Doubleclick to add slide text': 'Doubleclick to add slide text',
            'Internal Server Error': 'Server error, try again later!',
            'Forbidden': 'Server error, try again later!',
            'EmailVerified': 'Email successfully verified!',
            'VerifyEmailSent': 'We have sent you a confirmation EMail',
            
            // Editor...
            exportToPdfError: 'Can\'t export to PDF, try later',

            // Api errors...
            'internalError': 'Server error, please try again later',
            'presentation_not_found': 'Presentation not found',
            'error': 'Error, please try again later or contact us. support!',
            'failed_user_registration': 'Registration failed, please try later',
            'failed_user_login': 'Invalid login password pair',
            'user_email_update_change_cancel': 'Failed to reset new email',
            'user_has_no_templates': 'No templates',
            'no_files': 'Files missing',
            'invalid_password_recovery_code': 'Invalid password recovery code, the code was resent to the mail',
            'invalid_email': 'Invalid Email',
            'failed_to_send_message': 'Message not sent. Please try again later.',
            'no_templates': 'No templates',
            'presentation_quota_exceeded': 'Presentation limit exceeded',
            'passwords_do_not_match': 'Passwords do not match',
            'user_password_min': 'Minimum password length 6 characters',
            'password_min_length_6': 'Minimum password length 6 characters',
            'password_required': 'Password required',
            'password_reset_code_required': 'Password reset code required',
            'user_with_this_email_not_found': 'The user with the specified eMail does not exist',
            'email_required': 'Email required',
            'invalid_verify_code': 'Invalid confirmation code',
            'user_password_confirmed': 'Passwords do not match',

            'user_name_required': 'Enter your name',
            'user_name_filled': 'Enter your name',
            'user_name_min': 'Minimum name length 2 characters',
            'user_name_max': 'Maximum name length exceeded',
            'user_name_alpha_space': 'The name can only contain numbers and letters',

            'user_email_filled': 'Enter your Email',
            'user_email_email': 'Invalid Email',
            'user_email_unique': 'User with this Email already exists',
            'user_password_filled': 'Enter your password',

            'user_email_not_exists': 'This EMail is not registered',
            'Passwords do not match': 'Passwords do not match',
            'Password cannot be shorter than 6 symbols': 'Password cannot be shorter than 6 symbols',
            'Name cannot be longer than 20 symbols': 'Name cannot be longer than 20 symbols',
            'Password is incorrect': 'Password is incorrect',
            'Password incorrect': 'Password is incorrect',
            'Enter your password': 'Enter your password',
            'Account with specified email already exists': 'Account with specified email already exists',
            'Password successfully changed': 'Password successfully changed',
            'unknown_page': 'Page not found... Where did it go?',
            'unkown_error': 'Unknown error, what happened, who am I?',
            'pageNotFound': 'Page not found :('
        },
        ru: {
            'go_home': 'На главную',
            homeTitle: 'SlideLab: Создать презентацию онлайн',
            homeDescription: 'Создайте свою презентацию онлайн, поделитесь ей по ссылке ссылке, дайте доступ всем желающимей или скачайте ее из нашего редактора',
            docsTitle: 'SlideLab: Создать презентацию онлайн | Документация',
            policyTitle: 'SlideLab: Создать презентацию онлайн | Политика конфиденциальности',
            loginTitle: 'SlideLab: Создать презентацию онлайн | Авторизация',
            accountTitle: 'SlideLab: Создать презентацию онлайн | Аккаунт',
            managerTitle: 'SlideLab: Создать презентацию онлайн | Админка',
            editorTitle: 'SlideLab: Создать презентацию онлайн | Редактор',
            shareTitle: 'SlideLab: Создать презентацию онлайн | Просмотр презентации',
            presentationSearchTitle: 'SlideLab: Создать презентацию онлайн | Поиск презентаций',
            presentationPreviewTitle: 'SlideLab: Создать презентацию онлайн | Првеью презентации',
            presentationSearchDescription: 'Скачать готовую презентацию. Редактор и банк готовых презентаций SlideLab.',
            presentationPreviewDescription: 'Скачать готовую презентацию. Редактор и банк готовых презентаций SlideLab.',
            'Doubleclick to add slide title': 'Кликните дважды, чтобы добавить заголовок',
            'Doubleclick to add slide text': 'Кликните дважды, чтобы добавить текст',
            'Internal Server Error': 'Ошибка, попробуйте позже или свяжитесь с тех. поддержкой!',
            'Forbidden': 'Доступ запрещён!',
            'EmailVerified': 'Email успешно подтвержден!',
            'VerifyEmailSent': 'Мы отправили вам письмо для подтверждения EMail',
    
            // Editor...
            exportToPdfError: 'Не удалось экспортировать в PDF, попробуйте позже',

            // Api errors...
            'internalError': 'Ошибка сервера, попробуйте позже',
            'presentation_not_found': 'Презентация не найдена',
            'error': 'Ошибка, попробуйте позже или свяжитесь с тех. поддержкой!',
            'failed_user_registration': 'Регистрация не удалась, попробуйте позже',
            'failed_user_login': 'Неверная пара логин/пароль',
            'user_email_update_change_cancel': 'Не удалось сбросить новый email',
            'user_has_no_templates': 'Шаблоны отсутствуют',
            'no_files': 'Файлы отсутствуют',
            'invalid_password_recovery_code': 'Неверный код восстановления пароля, код повторно отправлен на почту',
            'invalid_email': 'Неверный EMail',
            'failed_to_send_message': 'Сообщение не отправлено. Повторите попытку позже.',
            'no_templates': 'Шаблоны отсутствуют',
            'presentation_quota_exceeded': 'Превышен лимит презентаций',
            'passwords_do_not_match': 'Пароли не совпадают',
            'user_password_min': 'Минимальная длина пароля 6 символов',
            'password_min_length_6': 'Минимальная длина пароля 6 символов',
            'password_required': 'Необходимо указать пароль',
            'password_reset_code_required': 'Необходимо указать код сброса пароля',
            'user_with_this_email_not_found': 'Пользователя с указанным EMail не существует',
            'email_required': 'Необходимо указать EMail',
            'invalid_verify_code': 'Неверный код подтверждения',
            'user_password_confirmed': 'Пароли не совпадают',

            'user_name_required': 'Укажите ваше имя',
            'user_name_filled': 'Укажите ваше имя',
            'user_name_min': 'Минимальное длина имени 2 символа',
            'user_name_max': 'Максимальная длина имени 20 символов',
            'user_name_alpha_space': 'Имя может содержать только цифры и буквы',

            'user_email_filled': 'Укажите ваш Email',
            'user_email_email': 'Указан неверный Email',
            'user_email_unique': 'Пользователь с таким Email уже существует',
            'user_password_filled': 'Укажите ваш пароль',

            'user_email_not_exists': 'Данный EMail не зарегистрирован',
            'Passwords do not match': 'Пароли не совпадают',
            'Password cannot be shorter than 6 symbols': 'Пароль не может быть короче 6 символов',
            'Name cannot be longer than 20 symbols': 'Имя не может быть длиннее 20 символов',
            'Password is incorrect': 'Неверный пароль',
            'Password incorrect': 'Неверный пароль',
            'Enter your password': 'Введите пароль',
            'Account with specified email already exists': 'Учетная запись с указанным EMail уже существует',
            'Password successfully changed': 'Пароль успешно изменен',
            'unknown_page': 'Страница не найдена... Куда делась?',
            'unkown_error': 'Неизвестная ошибка, что произошло, кто я?',
            'pageNotFound': 'Страница не найдена :('
        }
    }
    // messages: loadLocaleMessages()
});
