// @flow

import jwtDecode from 'jwt-decode';
import { apiGet } from '@/models/Api';

type UserSingletonType = {
    user: null | Object,
    token: null | string,
    getToken (): string | null,
    getInstance (): Object | null,
    setInstance (user: Object | null): void,
    setToken (token: string): void,
    saveToken (token: string): void,
    isTokenValid (token: string): boolean
};

export const UserSingleton: UserSingletonType = {
    user: null,
    token: null,
    getToken (): string | null {
        if (UserSingleton.token === null) {
            const token = localStorage.getItem('token');
            
            if (token) {
                UserSingleton.token = token;
            }
        }
        
        return UserSingleton.token;
    },
    async getInstance (): Object | null {
        if (UserSingleton.user !== null) {
            return UserSingleton.user;
        } else {
            const userToken = localStorage.getItem('user');
            
            if (userToken) {
                let user = null;
                
                try {
                    user = jwtDecode(userToken);
                } catch (e) {
                    console.log(e);
                }
                
                if (user) {
                    UserSingleton.user = user;
                    
                    return UserSingleton.user;
                }
            }
        }
        
        const token = UserSingleton.getToken();
        
        if (token) {
            const res = await apiGet('/api/v1/user');
            
            if (res && typeof res === 'string') {
                const user = jwtDecode(res);
                
                if (user) {
                    localStorage.setItem('user', res);
                    UserSingleton.user = user;
                }
            }
        }
        
        return UserSingleton.user;
    },
    setInstance (user: Object | null): void {
        UserSingleton.user = user;
    },
    setToken (token: string): void {
        UserSingleton.token = token;
    },
    saveToken (token: string): void {
        localStorage.setItem('token', token);
    },
    isTokenValid (token: string): boolean {
        if (!token) {
            return false;
        }
        
        const jwtData = jwtDecode(token) || {};
        
        if (jwtData.access_token) {
            const token = jwtDecode(jwtData.access_token) || {};
            const expires = token.exp || 0;
            
            return (new Date().getTime() / 1000) < expires;
        }
        
        return false;
    }
};
