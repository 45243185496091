<template>
    <div class="search-input-wrapper">
        <input v-model="search" :placeholder="searchPlaceholder" @keyup.enter.prevent="$emit('search', search)">
        <img @click="$emit('search', search)" src="@/assets/images/search.svg" class="search-icon" alt="search">
    </div>
</template>

<script>
export default {
    name: 'SearchInput',
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        search: '',
        placeholderData: ['one', 'two', 'three', 'four'],
        placeholderIndex: 0,
        placeholderPointer: 0,
        searchPlaceholder: ''
    }),
    methods: {
        animatePlaceholder (forward = true) {
            let text = this.$t(this.placeholderData[this.placeholderIndex]);
            if (forward) {
                this.searchPlaceholder = text.substring(0, this.placeholderPointer + 1);
                this.placeholderPointer++;

                if (text.length === this.placeholderPointer) {
                    this.$nextTick(() => {
                        setTimeout(() => this.animatePlaceholder(false), 850);
                        this.placeholderPointer = 0;
                    });
                    return;
                }

                setTimeout(() => this.animatePlaceholder(), 75);
            } else {
                this.searchPlaceholder = this.searchPlaceholder.slice(0, -1);

                if (this.searchPlaceholder.length === 0) {
                    this.placeholderIndex = this.placeholderIndex === this.placeholderData.length - 1
                        ? 0
                        : this.placeholderIndex + 1;

                    this.animatePlaceholder();
                    return;
                }

                setTimeout(() => this.animatePlaceholder(false), 50);
            }
        }
    },
    watch: {
        search (val) {
            this.$emit('search:update', val);
        }
    },
    created () {
        this.search = this.value;
    },
    mounted () {
        this.animatePlaceholder();
    }
};
</script>

<style scoped lang="scss">
.search-input-wrapper {
    position: relative;

    input {
        width: 100%;
        height: 55px;
        font-size: 18px;
        padding: 10px 20px;
        border-radius: 44px;
        box-sizing: border-box;
        border: 2px solid #CCC;
        background-color: transparent;
        transition: all 0.5s ease-out;

        &::placeholder {
            color: darkgray;
        }

        &:focus::placeholder {
            color: transparent;
        }

        &:focus, &:valid {
            cursor: auto;
            outline: none;
            color: #2d2d2d;
            border: 2px solid #CCC;
            background-color: #FFF;

            + .search-icon {
                z-index: 0;
                border-color: #CCC;
                right: 20px;

                &:after {
                    background-color: #CCC;
                }
            }
        }
    }

    .search-icon {
        top: 50%;
        right: 10px;
        cursor: pointer;
        user-select: none;
        position: absolute;
        transform: translateY(-50%);
    }

    &:before {
        top: 2px;
        right: 2px;
        bottom: 2px;
        content: '';
        width: 55px;
        position: absolute;
        border-top-right-radius: 44px;
        border-bottom-right-radius: 44px;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 100%);
    }
}
</style>

<i18n>
{
    "en": {
        "one": "Internet security",
        "two": "Programming in Python",
        "three": "Planets of the solar system",
        "four": "Psychology",
        "fife": "Artificial intelligence"
    },
    "ru": {
        "one": "Безопасность в интернете",
        "two": "Программрование на Python",
        "three": "Планеты солнечной системы",
        "four": "Психология",
        "fife": "Искусственный интеллект"
    }
}
</i18n>
