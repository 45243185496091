<template>
    <div class="home-page">
        <BaseHeader/>
        <div class="home-page__content home-page__content_loaded">
            <div class="page__block block1 block1_inv">
                <div class="block1__bg"></div>
                <h1 class="page__block-title">{{ $t('block1.title') }}</h1>
                <div class="page__block-text">
                    <p>{{ $t('block1.text') }}</p><br/>
                    <router-link
                        class="btn btn_try-app"
                        :to="tryAppUrl"
                        @click="$root.reachGoal('clickTryForFreeBlock1')"
                    >
                        <span class="btn__text">{{ $t('block1.btn') }}</span>
                    </router-link>
                    <div class="btn__shadow"></div>
                </div>
                <video id="video1" loop="loop" muted="muted" autoplay="autoplay">
                    <source src="@/assets/videos/howto.mp4" type="video/mp4"/>
                </video>
            </div>
            <div class="page__block presentation-search">
                <h2 class="page__block-title mb-5">
                    {{ $t('presentation-search.title') }}
                </h2>
                <div class="search-wrapper">
                    <div class="input-wrapper">
                        <SearchInput @search:update="val => search = val" @search="searchPresentation"/>
                    </div>
                </div>

                <div class="page__block-text">
                    <p class="mb-5">{{ $t('presentation-search.text') }}</p>

                    <div
                        class="btn btn_try-app"
                        @click="searchPresentation"
                    >
                        <span class="btn__text">{{ $t('presentation-search.btn') }}</span>
                    </div>
                    <div class="btn__shadow"></div>
                </div>
            </div>
            <div class="page__block block2">
                <h2 class="page__block-title">
                    {{ $t('block2.title') }}
                </h2>
                <div class="page__block-text"><p>{{ $t('block2.text') }}</p></div>
                <div class="home-page__slider">
                    <div
                        class="home-page__slider-item" v-for="(slide, idx) in view.slides"
                        :class="{'home-page__slider-item_active': idx === activeSlide}">
                        <img class="block2__img" :src="slide" alt="slide-example"/>
                    </div>
                    <div class="home-page__slider-bullets">
                        <div
                            class="home-page__slider-bullet" v-for="(slide, idx) in view.slides"
                            :class="{'home-page__slider-bullet_active': idx == activeSlide}"
                            @click="activeSlide = idx">
                        </div>
                    </div>
                </div>
            </div>
            <div class="page__block block3">
                <h2 class="page__block-title">
                    {{ $t('block3.title') }}
                </h2>
                <div class="page__block-text"><p>{{ $t('block3.text') }}</p></div>
                <div class="home-page__logos">
                    <img src="@/assets/images/ch.webp" alt="ch-icon"/>
                    <img src="@/assets/images/ff.webp" alt="ff-icon"/>
                    <img src="@/assets/images/op.webp" alt="op-icon"/>
                    <img src="@/assets/images/sf.webp" alt="sf-icon"/>
                    <img src="@/assets/images/pp.webp" alt="pp-icon"/>
                    <img src="@/assets/images/kn.webp" alt="kn-icon"/>
                    <img src="@/assets/images/oo.webp" alt="oo-icon"/>
                </div>
            </div>
            <div class="page__block block4">
                <h2 class="page__block-title">
                    {{ $t('block4.title') }}
                </h2>
                <div class="page__block-text"><p>{{ $t('block4.text') }}</p><br/>
                    <router-link
                        class="btn btn_try-app"
                        :to="tryAppUrl"
                        @click="$root.reachGoal('clickTryForFreeBlock2')"
                    >
                        <span class="btn__text">{{ $t('block1.btn') }}</span>
                    </router-link>
                    <div class="btn__shadow"></div>
                </div>
                <br/><br/><br/>
                <video id="video2" loop="loop" muted="muted">
                    <source src="@/assets/videos/transition.mp4" type="video/mp4"/>
                </video>
            </div>
        </div>
        <BaseFooter/>
    </div>
</template>

<script>
import Toast from '@/mixins/Toast';
import common from '@/mixins/Common';
import Analytics from '@/mixins/Analytics';

import { apiGet } from '@/models/Api';
import BaseHeader from '@/components/Layouts/BaseHeader';
import BaseFooter from '@/components/Layouts/BaseFooter';
import SearchInput from '@/components/SearchPresentations/SearchInput.vue';

export default {
    name: 'Home',
    components: {
        SearchInput,
        BaseHeader,
        BaseFooter
    },
    mixins: [Analytics, Toast],
    data () {
        return {
            isBeta: true,
            search: '',
            os: common.detectOs(),
            isMob: common.isMob(),
            activeSlide: 0,
            playing: {
                video1: false,
                video2: false
            },
            view: {
                sliderInterval: null,
                slides: [
                    '/main/slides/slide1.webp',
                    '/main/slides/slide2.webp',
                    '/main/slides/slide3.webp'
                ]
            }
        };
    },
    computed: {
        tryAppUrl () {
            return this.$store.state.user.isAuthenticated ? '/account' : '/login?mode=register';
        }
    },
    created () {
        window.addEventListener('scroll', this.scroll);
    },
    mounted () {
        this.view.sliderInterval = setInterval(() => this.nextSlide(), 5000);
        this.verifyEmail();
    },
    destroyed () {
        if (this.view.sliderInterval) {
            clearInterval(this.view.sliderInterval);
            this.view.sliderInterval = null;
        }
        window.removeEventListener('scroll', this.scroll);
    },
    methods: {
        scroll () {
            this.manageVideoPlayback('video1');
            this.manageVideoPlayback('video2');
        },
        nextSlide () {
            this.activeSlide = this.view.slides.length <= this.activeSlide + 1 ? 0 : this.activeSlide + 1;
        },
        isInViewport (el) {
            if (el == null) {
                return false;
            }
            const rect = el.getBoundingClientRect();
            const top = rect.y;
            const bottom = top + rect.height;
            return (top > 0 && top < window.innerHeight - 200) || (bottom > 0 && bottom < window.innerHeight);
        },
        async verifyEmail () {
            const { verify_code: verifyCode, email, type } = this.$route.query;
            if (verifyCode && email) {
                // Подтверждение: verify_email, Подтверждение смены: verify_change_email
                const urlType = type === 'verify_change_email' ? 'user' : 'register';
                const res = await apiGet(`/api/v1/${urlType}/confirm/${email}/${verifyCode}`);

                if (res) {
                    this.showSuccessToast('EmailVerified', 'top-center');

                    if (this.$store.state.user.isAuthenticated) {
                        await this.$store.commit('setUserParam', { key: 'verified', value: true });
                        await this.$router.push('/account');
                    }
                }
            }
        },
        async manageVideoPlayback (videoId) {
            const v = document.getElementById(videoId);
            if (v == null) {
                return;
            }

            if (this.isInViewport(v)) {
                if (!this.playing[videoId]) {
                    try {
                        await v.play();
                        this.playing[videoId] = true;
                    } catch (err) {
                        console.log(err);
                    }
                }
            } else {
                if (this.playing[videoId]) {
                    await v.pause();
                    this.playing[videoId] = false;
                }
            }
        },
        searchPresentation () {
            this.$router.push(`/presentation-search?search=${this.search}`);
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/common.scss';

.home-page {
    position: relative;
    display: block;
    font-family: 'Montserrat', sans-serif;
    padding-top: 48px;
    background: #fff;
    white-space: pre-line;

    .page__block {
        padding: 120px 0;
        @include global_padding;

        @media (max-width: 480px) {
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }

    .header {
        z-index: 2;
    }

    &__slider {
        position: relative;
        display: block;
        margin: 64px auto;
        padding-top: 57%;

        &-item {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            transition: all .9s ease;
            opacity: 0;

            &_active {
                // position:relative;
                transition: all .9s ease;
                opacity: 1;
            }

            img {
                position: relative;
                display: block;
                max-width: 100%;
            }
        }

        &-bullets {
            position: relative;
            display: flex;
            padding-top: 48px;
            justify-content: center;
        }

        &-bullet {
            position: relative;
            display: block;
            height: 8px;
            width: 8px;
            background: #ddd;
            border-radius: 50%;
            margin: 0 16px;
            transition: all .3s;
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                display: block;
                height: 24px;
                width: 24px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &_active {
                transform: scale(1.5);
                background: $accent_color1;

                &:hover {
                    background: $accent_color1;
                }
            }

            &:hover {
                transform: scale(1.5);
                background: #aaa;
            }
        }
    }

    &__logos {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        img {
            position: relative;
            display: block;
            flex-shrink: 0;
            transform: scale(0.5);
        }
    }

    &__content {
        position: relative;
        display: block;
        text-align: center;
        opacity: 0;
        transition: opacity 1s;

        &_loaded {
            opacity: 1;
        }
    }

    .page__block-text {
        max-width: 870px;
    }

    .btn_download-app, .btn_try-app {
        z-index: 1;
        margin: 0 auto;
        font-size: 16px;
        letter-spacing: 0;
        position: relative;
        padding: 10px 20px;
        align-items: center;
        text-transform: none;
        display: inline-flex;
        text-decoration: none;
        color: #fff !important;
        justify-content: center;
        transition: all .3s ease;
        background: linear-gradient(to right, $accent_color1 0%, $accent_color2 100%);

        &:hover {
            opacity: 1;
            transform: translateY(3px);
        }

        svg {
            position: relative;
            display: block;
            width: 16px;
            height: 19px;
            margin-right: 15px;
        }

        .btn__text {
            padding-left: 15px;
            border-left: 1px solid rgba(255, 255, 255, .4);
            font-weight: bold;
        }
    }

    .btn_try-app {
        .btn__text {
            padding-left: 0;
            border-left: none;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
        }
    }

    .btn__shadow {
        position: relative;
        display: block;
        margin: 0 auto;
        height: 40px;
        margin-bottom: -40px;
        width: 260px;
        background: linear-gradient(to right, $accent_color1 0%, $accent_color2 100%);
        border-radius: 14px;
        filter: blur(20px);
        transform: translateY(-70%);
        z-index: 0;
    }

    .block1 {
        padding-bottom: 0;
        margin-bottom: 70px;

        &_inv {
            color: #fff;

            h1 {
                color: #fff;
            }

            .block1__bg:after {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: #0026;
            }
        }

        &__bg {
            position: absolute;
            display: block;
            background: #F5F6F9;
            top: 0;
            right: 0;
            left: 0;
            height: 768px;
            background-size: cover;
            background-position: center;
            background-image: url('~@/assets/images/bg1.webp');

            @media (max-width: 768px) {
                height: 680px;
            }
        }

        .page__block-title {
            font-size: 42px;
            // font-weight: 500;
            // white-space: pre-line;
        }

        .page__block-text {
            max-width: 500px;
            margin-bottom: 64px;
            white-space: pre-line;
        }

        &__img {
            position: relative;
            display: block;
            max-width: 100%;
            margin: 0 auto;
        }

        &__video {
            position: relative;
            display: block;
            width: 100%;
            max-width: 1280px;
            margin: 100px auto;
        }

        video {
            position: relative;
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }

        .embed-container {
            position: relative;
            padding-bottom: 55.25%;
            height: 0;
            overflow: hidden;
            width: 100%;
            margin: 0 auto;

            @media (min-width: 1600px) {
                width: 1280px;
                padding-bottom: 720px;
            }

            // @media (max-width: 768px) {
            //   width: auto;
            //   left: -40px;
            //   right: -40px;
            // }

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .presentation-search {
        padding-top: 60px;
        background: #F5F6F9;
        padding-bottom: 60px;

        .search-wrapper {
            .input-wrapper {
                width: 90%;
                margin: auto;
                max-width: 900px;
                position: relative;
            }
        }
    }

    .block2 {
        margin: 0 auto;
        max-width: 1300px;
    }

    .block3 {
        background: #F5F6F9;
    }

    .block4 {
        video {
            position: relative;
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
    }

    .block5 {
        background: linear-gradient(to right, $accent_color1 0%, $accent_color2 100%);
        color: #fff;

        .btn_download-app, .btn_try-app {
            background: rgba(255, 255, 255, .2);

            .btn__text {
                color: #fff;
            }
        }

        .btn__shadow {
            background: #9552;
        }
    }
}
</style>

<i18n>
{
    "en": {
        "block1": {
            "title": "Create better \n presentations faster",
            "text": "SlideLab is a new platform for creating modern professional interactive presentations",
            "img": "lp1.png",
            "vimeo": "359077748",
            "file": "howto.mp4",
            "btn": "Try SlideLab for free",
            "bg": "bg1.webp"
        },
        "presentation-search": {
            "title": "Find the right presentation in one click",
            "text": "The Presentation Bank contains the public presentations of users that they have shared with the community. Using the presentation search, you can pick up a ready-made presentation, copy it to yourself, and then edit or optimize it to your requirements. This greatly saves your time and speeds up the process of creating a presentation.",
            "btn": "More presentations"
        },
        "block2": {
            "title": "Simple workflow \n and export",
            "text": "SlideLab offers a powerful editor with enhanced UI. Anything you need at the moment is available in 2-3 clicks. Real-time cloud-sync, media library with fast media search over the Internet and many other features will speed up your workflow significantly.",
            "slides": [
                "lp2.jpg",
                "lp3.jpg",
                "lp4.jpg"
            ]
        },
        "block3": {
            "title": "Share your project \n in 1 click",
            "text": "Now there is no need in any special software to open your presentation. All you need is a modern web browser. However you can export your project to PDF and PPTX formats and open it in PowerPoint, KeyNote and Open Office."
        },
        "block4": {
            "title": "More new features \n are coming",
            "text": "Our goal is to build the best software for creating awesome and impressive presentations. New amazing slide transitions, professional presentation templates, smart components and many other cool features are coming soon.",
            "file": "transition.mp4"
        },
        "block5": {
            "title": "Become an Early Adopter!",
            "text": "SlideLab is in active development now. We are working hard to make our service stable and bug free. Any kind of feedback is appreciated. We invite you to become part of the early adopter's program.",
            "btn": "Try SlideLab for free"
        },
        "isBeta": true,
        "betaMsg": "SlideLab is in beta phase. We are working hard to make our service stable and bug free. Any kind of feedback is appreciated.",
        "privacyPolicy": "Privacy policy",
        "contactUs": "Contact us",
        "cookiesMsg": "We use cookies to keep you logged in and to provide seamless experience of the service. Please read our <a href='/policy' target='blank'>Privacy policy</a> for details."
    },
    "ru": {
        "block1": {
            "title": "Создавайте более качественные \n презентации быстрее",
            "text": "SlideLab — новая платформа для создания современных профессиональных интерактивных презентаций",
            "btn": "Попробуйте SlideLab бесплатно"
        },
        "presentation-search": {
            "title": "Найди нужную презентацию в один клик",
            "text": "Банк презентаций содержит общедоступные презентации пользователей, которыми они поделились с сообществом. С помощью поиска презентаций, вы можете подобрать готовую презентацию, скопировать ее себе, а затем отредактировать или оптимизировать ее под свои требования. Это сильно экономит ваше время и ускоряет процесс создания презентации.",
            "btn": "Больше презентаций"
        },
        "block2": {
            "title": "Простой рабочий процесс \n и экспорт",
            "text": "SlideLab предлагает мощный редактор с улучшенным пользовательским интерфейсом. Все, что вам нужно в данный момент, доступно в 2-3 клика. Облачная синхронизация в режиме реального времени, медиатека с быстрым поиском медиа через Интернет и многие другие функции значительно ускорят ваш рабочий процесс."
        },
        "block3": {
            "title": "Поделитесь своей презентациией \n в 1 клик",
            "text": "Теперь для открытия презентации не требуется никакого специального программного обеспечения. Все, что вам нужно, — это современный веб-браузер. Однако вы можете экспортировать свою презентацию в форматы PDF и PPTX и открывать его в PowerPoint, KeyNote и Open Office"
        },
        "block4": {
            "title": "Будут добавлены новые функции",
            "text": "Наша цель — создать лучшее программное обеспечение для создания потрясающих и впечатляющих презентаций. Скоро появятся новые потрясающие переходы между слайдами, профессиональные шаблоны презентаций, интеллектуальные компоненты и множество других интересных функций",
            "file": "transition.mp4"
        },
        "block5": {
            "title": "Become an Early Adopter!",
            "text": "SlideLab is in active development now. We are working hard to make our service stable and bug free. Any kind of feedback is appreciated. We invite you to become part of the early adopter's program.",
            "btn": "Try SlideLab for free"
        },
        "betaMsg": "SlideLab находится в стадии бета-тестирования. Мы прилагаем все усилия, чтобы сделать наш сервис стабильным и свободным от ошибок. Любая обратная связь приветствуется.",
        "privacyPolicy": "Политика конфиденциальности",
        "contactUs": "Обратная связь",
        "cookiesMsg": "Мы используем файлы cookie, чтобы вы оставались в системе и обеспечивали бесперебойную работу службы. Подробнее в нашей <a href='/policy' target='blank'>Политике конфиденциальности</a>"
    }
}
</i18n>
