export default function goToPage ({ to, next }) {
    if (document) {
        if (to.path === '/editor') {
            document.body.classList.add('editor-body', 'notranslate');
        } else {
            document.body.classList.remove('editor-body', 'notranslate');
        }
    }
    
    return next();
}
