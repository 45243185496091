// @flow
/* eslint-disable */

export default {
    methods: {
        showToast(msg: string, type: string, position: string) {
            this.$toast[type](this.$t(msg), {
                position: position,
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: false,
                showCloseButtonOnHover: true,
                closeButton: 'button',
                icon: true,
                rtl: false
            });
        },
        showInfoToast(msg: string, position: string = 'bottom-right') {
            this.showToast(msg, 'info', position);
        },
        showErrorToast(msg: string, position: string = 'bottom-right') {
            this.showToast(msg, 'error', position);
        },
        showSuccessToast(msg: string, position: string = 'bottom-right') {
            this.showToast(msg, 'success', position);
        }
    }
};
