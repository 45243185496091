/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex'
import _ from "lodash";
import { detailedDiff } from 'deep-object-diff';

import CLS from '@/mixins/CustomLocalStorage';
import {GetFromWindow} from "@/models/CurrentPage";

import UserStore from './ext/UserStore';
import OSR from './ext/ObjectStylesReference';
import defaultStyles from './ext/DefaultStyles';
import PresentationStore from './ext/Presentation';
import ViewerStore from '@/store/ext/ViewerStore';

const indexOf = [].indexOf;

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user: UserStore,
    presentation: PresentationStore,
    viewer: ViewerStore
  },
  state: {
    sentry: undefined,
    presentationId: typeof localStorage !== "undefined" && localStorage !== null ? localStorage.getItem('presentationId') : 0,
    userActions: [],
    title: 'Editor',
    activeSlide: undefined,
    toolbar: {
      currentActive: undefined
    },
    objectTypes: {
      shapes: ['rectangle', 'circle', 'rhombus', 'triangle', 'parallelogram', 'chevron', 'arrow'],
      curves: ['line']
    },
    historyAvailible: {
      undo: false,
      redo: false
    },
    defaultSettings: {
      groupsCollapsed: {
        'Background': false,
        'Appearance': false,
        'Shape': false,
        'Fill': true,
        'Stroke': true,
        'Text': false,
        'Arrows': true,
        'Animation': false
      },
      showRulers: true,
      showGuides: true,
      showPalette: true
    },
    previewsHtmls: [],
    vjsons: {
      slides: [],
      size: {
        h: 0,
        w: 0
      }
    },
    slideTemplates: [],
    blanks: {
      p: {
        style: {
          'list-type': 'none',
          'list-style-type': '',
          'list-level': 0,
          'line-height': 38
        },
        spans: [
          {
            content: '',
            style: {
              'color': {
                'palette': 'gsl0',
                'own': false
              },
              'font-size': 32,
              'font-family': 'Roboto',
              'font-weight': 'normal',
              'font-style': 'normal',
              'text-decoration': 'none',
              'text-transform': 'none',
              'background-color': {
                'palette': false,
                'own': [255,
                  255,
                  255,
                  0]
              },
              'letter-spacing': 0
            }
          }
        ]
      },
      border: {
        'color': {
          'palette': 'gsl0',
          'own': false
        },
        'width': 1,
        'stroke-dash': [0, 0],
        'stroke-type': 'solid'
      }
    },
    animationBlank: {
      'animation-autoplay': false,
      'animation-autoplay-delay': 1000,
      'animation-effect': 'right',
      'animation-easing': 'none',
      'animation-duration': 500
    },
    defaultAnimationState: {
      tx: 0,
      ty: 0,
      scale: 1,
      opacity: 1
    },
    themes: {},
    themeCache: null,
    mediaDataURLs: {},
    flags: {
      bgEditingMode: false,
      bgEditingProcess: false,
      screenshotGeneratorMode: false,
      pdfGeneratorMode: false
    },
    vars: {
      initialCoords: {
        x: 0,
        y: 0
      },
      coordsDiff: {
        x: 0,
        y: 0
      },
      svgs: false
    },
    defaults: {
      tableColWidth: 150,
      tableRowHeight: 65,
      svgExtend: 500
    }
  },
  mutations: {
    // clearMediaUsed:(state)->
    //   state.mediaUsed = {}
    //   return

    // loadMediaUsed:(state, mediaUsed)->
    //   state.mediaUsed = mediaUsed
    //   return

    // setMediaUsed:(state, opts)->
    //   if !opts?.media? or !opts.used?
    //     console.log('setMediaUsed error: invalid data', opts)
    //     return
    //   if !state.mediaUsed?
    //     state.mediaUsed = {}
    //   opts.media.map((m)->
    //     state.mediaUsed[m] = opts.used
    //     return
    //   )
    //   return
    cleanUnusedMedia(state){ // из state.presentation.media
      // console.log 'cleanUnusedMedia'
      let bgIds = []; // внутренние id ресурсов презентации
      state.presentation.slides.map(function(s){
        if (s.bg['background-image'] != null) {
          bgIds.push(s.bg['background-image']);
        }
        bgIds.push(s.objects.map(o => o.styleProps?.['background-image']));
      });
      if(localStorage.objectsClipboard != null) {
        const objectsClipboard = JSON.parse(localStorage.objectsClipboard).objects;
        bgIds.push(objectsClipboard.map(o => o.styleProps?.['background-image']));
      }
      bgIds = _.uniq(_.flatten(bgIds)); // внутренние id ресурсов презентации
      // console.log bgIds
      const mediaIds = Object.keys(state.presentation.media).map(id => parseInt(id));
      // console.log mediaIds
      const notUsedIds = _.difference(mediaIds, bgIds); // неиспользуемые внутренние id ресурсов презентации
      const mediaSqlIds = notUsedIds.map(id => state.presentation.media[id]['id']);

      // console.log('notUsedIds', notUsedIds)
      // console.log('mediaSqlIds', mediaSqlIds)

      // @commit('setMediaUsed', {
      //   media: mediaSqlIds
      //   used: false
      // })
      notUsedIds.map(function(id){
        delete state.presentation.media[id];
      });
    },

    setMedia(state, opts){
      // console.log 'setMedia',opts
      // если уже есть такой элемент, затираем его,
      // но пишем в тот же id
      let id;
      if (opts.mediaId != null) { // для unsplash
        id = opts.mediaId; // для unsplash
        delete opts.mediaId; // для unsplash
      } else {
        // если нет, берем новый id
        id = state.presentation.lastId;
        this.commit('incrementId');
      }
      // записываем
      Vue.set(state.presentation.media, id, opts);
    },

    incrementId(state){
      if ((state.presentation.lastId == null)) {
        state.presentation.lastId = 1;
        // console.log 'state.presentation.lastId '+state.presentation.lastId
        return;
      }
      // console.log 'state.presentation.lastId '+state.presentation.lastId
      state.presentation.lastId++;
    },

    setSlideAnimation(state, opts){

      const {
        animation
      } = state.presentation;

      if (animation[opts.key] === opts.value) {
        return;
      }

      if (opts.key === 'type') {

        const {
          slides
        } = state.presentation;

        if (slides.length <= 1) {
          if (animation.type !== 'none') {
            animation.type = 'none';
          }
          return;
        }
      }

      animation[opts.key] = opts.value;


    },

    deleteMedia(state, ids){
      // console.log ids
      const mediaIds = [];
      _.each(state.presentation.media, function(v, k){
        if (_.indexOf(ids, v.id)!==-1) {
          mediaIds.push(parseInt(k));
        }
      });
      // console.log mediaIds
      _.each(state.presentation.slides, function(slide){
        // console.log slide.id, slide.bg['background-image']
        if(_.indexOf(mediaIds, slide.bg['background-image']) !== -1) {
          slide.bg['background-image'] = '';
        }
        if(_.indexOf(mediaIds, slide.bg['background-video']) !== -1) {
          slide.bg['background-video'] = '';
        }
        // проверить!
        _.each(slide.objects, function(object){
          // console.log object
          if(_.indexOf(mediaIds, object.styleProps?.['background-image']) !== -1) {
            object.styleProps['background-image'] = '';
          }
          if(_.indexOf(mediaIds, object.styleProps?.['background-video']) !== -1) {
            object.styleProps['background-video'] = '';
          }
        });
      });
      mediaIds.map(function(id){
        delete state.presentation.media[id];
      });
      this.commit('saveHistory', 'deleteMedia');
    },

    setTitle(state, title){
      state.title = title;
    },

    addNewSlide(state, options){

      if (options == null) { options = {}; }
      if (options.afterIndex == null) { options.afterIndex = state.presentation.slides.length; }

      const theTheme = state.presentation.theme;
      console.log(theTheme);

      const bgParamList = [
        'vueObj',
        'background-image',
        'background-color',
        'gradient',
        'color-mode',
        'image-opacity',
        'image-scale',
        'image-fill-mode',
        'background-video',
        'video-opacity',
        'background-mode',
        'image-custom-scale',
        'image-shift-x',
        'image-shift-y'
      ];
      const bgParams = {};
      bgParamList.map(function(param){
        if (param === 'background-color') {
          bgParams[param] = {
            'own': false,
            'palette': theTheme.colorOptions.slideBg
          };
        } else {
          bgParams[param] = defaultStyles[param];
        }
      });

      const newSlide = {
        id: state.presentation.lastId,
        active: false,
        name: 'slide-' + state.presentation.lastId,
        vueObj: {},
        bg: bgParams,
        objects:[],
        groups:[],
        animations: [],
        guides: {
          x: [],
          y: []
        }
      };

      this.commit('incrementId');

      const myDefaultStyles = {};
      _.forEach(OSR['rectangle'], function(style){
        if (style instanceof Array) {
          myDefaultStyles[style[0]] = _.cloneDeep(style[1]);
        } else {
          myDefaultStyles[style] = _.cloneDeep(defaultStyles[style]);
        }
      });

      const bc = 'background-color';
      const sc = 'stroke-color';

      if (myDefaultStyles[bc] != null) {
        myDefaultStyles[bc].palette = false;
        myDefaultStyles[bc].own = [255, 255, 255, 0];
      }

      const left = state.presentation.size.w * 0.05;
      const right = state.presentation.size.w * 0.95;

      const top1 = state.presentation.size.h * 0.119;
      const bottom1 = state.presentation.size.h * 0.205;

      const top2 = state.presentation.size.h * 0.26;
      const bottom2 = state.presentation.size.h * 0.8;

      const titleFz = Math.round(state.presentation.size.h * 0.060);
      const textFz = Math.round(state.presentation.size.h * 0.037);

      const objBlank = {
        creating: false,
        selectSelf: false,
        afterCreate: undefined,

        vueObj: {},
        type: 'rectangle',
        styleProps: myDefaultStyles,
        rotate: 0,
        text: {
          ps:[],
          style: {
            'text-align': 'left',
            'vertical-align': 'top',
            'paddings': [5, 5, 5, 5]
          }
        }
      };

      const titleObj = _.cloneDeep(objBlank);

      titleObj.id = state.presentation.lastId;

      titleObj.coords = [{
        x: left,
        y: top1
      }
        , {
          x: right,
          y: bottom1
        }
      ];

      titleObj.text.ps.push(_.cloneDeep(this.getters.blanks.p));
      titleObj.placeholder = {
        text: 'Doubleclick to add slide title',
        lh: Math.round(titleFz * 1.18),
        fz: titleFz,
        fw: 'bold'
      };
      titleObj.text.ps[0].style['line-height'] = Math.round(titleFz * 1.18);
      titleObj.text.ps[0].spans[0].style['font-size'] = titleFz;

      this.commit('incrementId');

      const textObj = _.cloneDeep(objBlank);

      textObj.id = state.presentation.lastId;

      textObj.coords = [{
        x: left,
        y: top2
      }
        , {
          x: right,
          y: bottom2
        }
      ];

      textObj.text.ps.push(_.cloneDeep(this.getters.blanks.p));
      textObj.placeholder = {
        text: 'Doubleclick to add slide text',
        lh: Math.round(textFz * 1.18),
        fz: textFz,
        fw: 'normal'
      };
      textObj.text.ps[0].style['line-height'] = Math.round(textFz * 1.18);
      textObj.text.ps[0].spans[0].style['font-size'] = textFz;

      newSlide.objects.push(titleObj);
      newSlide.objects.push(textObj);

      state.presentation.slides.splice(
          options.afterIndex,
          0,
          newSlide
      );
      this.commit('incrementId');
      this.commit('setActiveSlide', options.afterIndex);
      this.commit('saveHistory', 'addNewSlide');
    },

    moveSlide(state, opts){
      //console.log opts.id, opts.newPosition
      if (opts.id === opts.newPosition) {
        return;
      }
      const slide = _.assign({}, state.presentation.slides[opts.id]);
      const slidePreview = _.assign({}, state.previewsHtmls[opts.id]);
      //slide.vueObj = {}
      state.presentation.slides.splice(opts.id, 1);
      state.presentation.slides.splice(opts.newPosition, 0, slide);
      state.previewsHtmls.splice(opts.id, 1);
      state.previewsHtmls.splice(opts.newPosition, 0, slidePreview);
      this.commit('saveHistory', 'moveSlide');
    },

    removeSlide(state, id){
      if(state.presentation.slides.length<=1) {
        // console.log 'error: the only slide'
        return;
      }

      const wasActive = this.state.presentation.slides[id].active;
      const wasLast = this.state.presentation.slides.length === (id + 1);

      if(wasActive&&(state.presentation.slides.length>1)) {
        if(!wasLast) {
          this.commit('setActiveSlide', id + 1);
        } else {
          this.commit('setActiveSlide', id - 1);
        }
      }

      state.presentation.slides.splice(id, 1);
      this.commit('cleanUnusedMedia');
      this.commit('saveHistory', 'removeSlide');
    },

    addSlideFromTemplate(state, templateId){

      const noTemplate = templateId === -1;
      if (noTemplate) {
        this.commit('addNewSlide');
        return;
      }

      // Метод дублирует код cloneSlide, сделать рефактор

      const template = state.slideTemplates[templateId];
      const {
        presentation
      } = state;

      // копируем содержимое слайда без vueObj

      const idMappings = {};

      // Массивы урлов медиа-объектов шаблона и презы
      const presentationMediaObjectKeys = Object.keys(presentation.media);
      const presentationMediaUrls = presentationMediaObjectKeys.map(key => presentation.media[key].url);

      // Обнуляем vueObj's и создаем маппинг id-шников
      const slide = _.cloneDeepWith(
          template.slides[0],
          (val, key)=> {

            switch (key) {
              case 'vueObj':
                return {};
                break;
              case 'id':
                var newid = state.presentation.lastId;
                idMappings[val] = newid;
                this.commit('incrementId');
                return newid;
                break;
                // Отличительная особенность метода: добавлен ключ 'background-image'
              case 'background-image':

                if (!val) {
                  return;
                }

                // Необходимо проверить, не добавляли ли уже эту media в презу
                var templateMediaUrl = template.media[val].url;
                var possiblePresentationMediaIndex = presentationMediaUrls.indexOf(templateMediaUrl);
                if (possiblePresentationMediaIndex !== -1) {
                  const foundPresentationMediaIndex = possiblePresentationMediaIndex;
                  const presentationMediaId = presentationMediaObjectKeys[foundPresentationMediaIndex];

                  return presentationMediaId;
                } else {
                  newid = state.presentation.lastId;
                  idMappings[val] = newid;
                  this.commit('incrementId');
                  return newid;
                }
                break;
            }

          });

      // Меняем группы
      slide.groups = _.cloneDeepWith(
          slide.groups,
          function(val){
            if (typeof val === 'number') {
              if (idMappings[val] != null) {
                return idMappings[val];
              } else {
                return val;
              }
            }
          });

      // Меняем анимации
      slide.animations = slide.animations.map(function(as){
        if (Array.isArray(as)) {
          return as.map(function(a){
            if (idMappings[a] != null) {
              return idMappings[a];
            } else {
              return a;
            }
          });
        }
      });

      // Меняем привязанные точки у линий
      slide.objects.forEach(function(o){
        const ld = o.linkedDots;
        if (ld) {
          ['start', 'end'].forEach(function(k){
            if (ld[k]?.[0] != null) {
              ld[k][0] = idMappings[ld[k][0]];
            }
          });
        }
      });

      // Добавляем media из шаблона в презу
      const templateMediaIds = Object.keys(template.media);
      templateMediaIds.forEach(function(templateMediaId){
        if (idMappings[templateMediaId] != null) {
          Vue.set(
              state.presentation.media,
              idMappings[templateMediaId],
              template.media[templateMediaId]
          );
        }
      });

      const afterIndex = state.presentation.slides.length;

      slide.active = false;
      slide.id = state.presentation.lastId;
      this.commit('incrementId');
      state.presentation.slides.splice(afterIndex, 0, slide);
      setTimeout(()=> {
            this.commit('setActiveSlide', afterIndex);
            this.commit('saveHistory', 'addSlideFromTemplate');
          }
          , 30);

    },

    cloneSlide(state, id){

      // Метод дублирует код addSlideFromTemplate, сделать рефактор

      // копируем содержимое слайда без vueObj

      const idMappings = {};

      // Обнуляем vueObj's и создаем маппинг id-шников
      const slide = _.cloneDeepWith(state.presentation.slides[id], (val, key)=> {
        if (key==='vueObj') {
          return {};
        }
        if (key==='id') {
          const newid = state.presentation.lastId;
          idMappings[val] = newid;
          this.commit('incrementId');
          return newid;
        }
      });

      // Меняем группы
      slide.groups = _.cloneDeepWith(
          slide.groups,
          function(val){
            if (typeof val === 'number') {
              if (idMappings[val] != null) {
                return idMappings[val];
              } else {
                return val;
              }
            }
          });

      // Меняем анимации
      slide.animations = slide.animations.map(function(as){
        if (Array.isArray(as)) {
          return as.map(function(a){
            if (idMappings[a] != null) {
              return idMappings[a];
            } else {
              return a;
            }
          });
        }
      });

      // Меняем привязанные точки у линий
      slide.objects.forEach(function(o){
        const ld = o.linkedDots;
        if (ld) {
          ['start', 'end'].forEach(function(k){
            if (ld[k]?.[0] != null) {
              ld[k][0] = idMappings[ld[k][0]];
            }
          });
        }
      });

      slide.active = false;
      slide.id = state.presentation.lastId;
      this.commit('incrementId');
      state.presentation.slides.splice(id+1, 0, slide);
      const that = this;
      setTimeout(function(){
            that.commit('setActiveSlide', id+1);
            that.commit('saveHistory', 'cloneSlide');
          }
          , 30);
    },

    setActiveSlide(state, id){
      if((state.presentation.slides == null)) {
        return;
      }
      const activeSlide = this.getters.currentActiveSlide;
      if (activeSlide!==false) {
        state.presentation.slides[activeSlide].active = false;
      }
      state.presentation.slides[id].active = true;
      state.activeSlide = state.presentation.slides[id];
      // @commit('replaceHistory', 'setActiveSlide')
    },

    nextActiveSlide(state){
      const activeSlide = this.getters.currentActiveSlide;
      if (activeSlide < (state.presentation.slides.length - 1)) {
        this.commit('setActiveSlide', activeSlide + 1);
      }
    },

    prevActiveSlide(state){
      const activeSlide = this.getters.currentActiveSlide;
      if (activeSlide > 0) {
        this.commit('setActiveSlide', activeSlide - 1);
      }
    },

    setPresentation(state, presentation){

      presentation.editingObjectsIds = [];
      state.presentation = presentation;
      if (presentation.slides.length === 0) {
        this.commit('addNewSlide');
      }
      this.commit('setActiveSlide', 0);
      this.history = [[],[]];


      this.commit('saveHistory', 'setPresentation');

    },

    setSlideVue(state, opts){
      state.presentation.slides[opts.index].vueObj = opts.vue;
    },

    setBgVue(state, opts){
      state.presentation.slides[opts.index].bg.vueObj = opts.vue;
    },

    setObjectVue(state, opts){
      const slide = state.presentation.slides.find(s => s.id === opts.slideId);
      if (slide != null) {
        const object = slide.objects.find(o => o.id === opts.id);
        if (object != null) {
          object.vueObj = opts.vueObj;
        }
      }
      // state.presentation.slides[opts.slideIndex].objects[opts.index].vueObj = opts.vue
    },

    updateObjectProperty(state, opts){
      //opts.target.styleProps[opts.key] = opts.value
      if(opts.propset[opts.key] === opts.value) {
        // console.log 'no need to update'
        return;
      }
      opts.propset[opts.key] = opts.value;
    },

    moveObjects(state, opts){
      let diff;
      const currentSlideIndex = state.activeSlide.vueObj.slideIndex;
      const slide = state.presentation.slides[currentSlideIndex];

      const maxIndex = Math.max(...opts.indexes);
      const minIndex = Math.min(...opts.indexes);

      const upGap = slide.objects.length - 1 - maxIndex;
      const downGap = minIndex;

      if(slide.objects.length === opts.indexes.length) {
        return;
      }

      // Запоминаем ссылки на vue объекты
      const vueObjs = slide.objects.map(obj => obj.vueObj);

      switch (opts.direction) {
        case 'up':
          if (upGap === 0) {
            return;
          }
          diff = 1;
          break;
        case 'down':
          if (downGap === 0) {
            return;
          }
          diff = -1;
          break;
        case 'toFront':
          if (upGap === 0) {
            return;
          }
          diff = upGap;
          break;
        case 'toBack':
          if (downGap === 0) {
            return;
          }
          diff = -downGap;
          break;
      }

      if (diff < 0) {
        // Сортируем индексы по возрастанию
        opts.indexes.sort(function(a, b){
          if (a > b) {
            return 1;
          } else {
            return -1;
          }
        });
      }

      if (diff > 0) {
        // Сортируем индексы по убыванию
        opts.indexes.sort(function(a, b){
          if (a < b) {
            return 1;
          } else {
            return -1;
          }
        });
      }

      for (let i = 0, end = opts.indexes.length; i < end; i++) {

        const oldIndex = opts.indexes[i];
        const newIndex = oldIndex + diff;

        const object = slide.objects[oldIndex];
        slide.objects.splice(oldIndex, 1);
        slide.objects.splice(newIndex, 0, object);
      }

      // Обновить привязки у объектов
      _.forEach(vueObjs, function(vueObj){
        vueObj.updateVueLink(true);
      });

      this.commit('saveHistory', 'order');

    },

    setComponentValue(state, params){
      state.presentation.slides[params.slideIndex].objects[params.objectIndex][params.paramName] = params.value;
    },

    addComponent(state, opts){
      let end, height, newComponent, width;
      const {
        type
      } = opts;

      const theTheme = state.presentation.theme;

      // Отдельная логика для таблицы
      if (type === 'table') {

        let i;
        let end1;
        const colWidth = state.defaults.tableColWidth;
        const rowHeight = state.defaults.tableRowHeight;

        const borderBlank = _.cloneDeep(this.getters.blanks.border);

        const pBlank = _.cloneDeep(this.getters.blanks.p);

        pBlank.style['line-height'] = 40;
        pBlank.spans[0].style['font-size'] = 32;

        const cellBlank = {
          text: {
            ps:[pBlank],
            style: {
              'text-align': 'center',
              'vertical-align': 'middle',
              'paddings': [10, 10, 10, 10]
            }
          },
          colspan: 1,
          rowspan: 1,
          borders: {
            left: _.cloneDeep(borderBlank),
            right: _.cloneDeep(borderBlank),
            top: _.cloneDeep(borderBlank),
            bottom: _.cloneDeep(borderBlank)
          },
          style: {
            fill: null
          }
        };

        const borders = {
          hor: [0],
          ver: [0]
        };
        const bordersWanted = {
          hor: [0],
          ver: [0]
        };

        const rows = [];

        const rowBlank = {
          cells: [],
          style: {}
        };

        width = 0;
        height = 0;

        for (i = 1, end = opts.cols; i <= end; i++) {
          borders.ver.push(colWidth);
          bordersWanted.ver.push(colWidth);
          rowBlank.cells.push(_.cloneDeep(cellBlank));
          width += colWidth;
        }

        for (i = 1, end1 = opts.rows; i <= end1; i++) {
          borders.hor.push(rowHeight);
          bordersWanted.hor.push(rowHeight);
          rows.push(_.cloneDeep(rowBlank));
          height += rowHeight;
        }

        const startX = (this.state.presentation.size.w - width)/2;
        const startY = (this.state.presentation.size.h - height)/2;

        newComponent = {
          id: state.presentation.lastId,
          vueObj: {},
          type,
          creating: true,
          coords: [{
            x: startX,
            y: startY
          }
            , {
              x: startX + width,
              y: startY + height
            }
          ],
          tableData: {
            borders,
            bordersWanted,
            rows,
            style: {
              tableBg1Color: {
                palette: theTheme.colorOptions.tableBg1,
                own: false
              },
              tableBg2Color: {
                palette: theTheme.colorOptions.tableBg2,
                own: false
              },
              tableAccColor: {
                palette: theTheme.colorOptions.tableAcc,
                own: false
              },
              rowsAlt: true,
              colsAlt: false,
              firstCol: false,
              lastCol: false,
              firstRow: true,
              lastRow: false,
              opacity: 1
            }
          }
        };

        this.commit('incrementId');
        state.activeSlide.objects.push(newComponent);
        this.commit('saveHistory', 'Table created');
        return;
      }

      // Если не таблица
      const that = this;

      // Генерируем дефолтные стили
      const myDefaultStyles = {};
      _.forEach(OSR[type], function(style){
        if (style instanceof Array) {
          myDefaultStyles[style[0]] = _.cloneDeep(style[1]);
        } else {
          myDefaultStyles[style] = _.cloneDeep(defaultStyles[style]);
        }
      });

      const bc = 'background-color';
      const sc = 'stroke-color';
      const sw = this.state.presentation.size.w;
      const sh = this.state.presentation.size.h;

      if (myDefaultStyles[bc] != null) {
        myDefaultStyles[bc].palette = theTheme.colorOptions.objectBg;
      }
      if (myDefaultStyles[sc] != null) {
        myDefaultStyles[sc].palette = theTheme.colorOptions.objectStroke;
      }

      newComponent = {
        creating: opts.draw,
        selectSelf: !opts.draw,
        afterCreate: opts.afterCreate,

        id: state.presentation.lastId,
        vueObj: {},
        type,
        styleProps: myDefaultStyles,

        coords:[{
          x: (sw/2) - (sw/4),
          y: (sh/2) - (sh/4)
        }
          , {
            x: (sw/2) + (sw/4),
            y: (sh/2) + (sh/4)
          }
        ],
        rotate: 0
      };

      if (opts.coords != null) {
        newComponent.coords = opts.coords;
      }

      if (opts.size != null) {

        let ow = opts.size.width;
        let oh = opts.size.height;

        const wRatio = sw / ow;
        const hRatio = sh / oh;

        const reducer = Math.min(wRatio, hRatio);
        if (reducer < 1) {
          ow = ow * reducer;
          oh = oh * reducer;
        }

        newComponent.coords = [{
          x: (sw/2) - (ow/2),
          y: (sh/2) - (oh/2)
        }
          , {
            x: (sw/2) + (ow/2),
            y: (sh/2) + (oh/2)
          }
        ];
      }

      if (this.state.objectTypes.shapes.includes(type)) {
        newComponent.text = {
          ps:[],
          style: {
            'text-align': 'left',
            'vertical-align': 'top',
            'paddings': [10, 10, 10, 10]
          }
        };
      }

      if (this.state.objectTypes.curves.includes(type)) {
        newComponent.linkedDots = {
          start:[],
          end:[]
        };
      }

      this.commit('incrementId');

      if (opts.props != null) {
        Object.entries(opts.props).map(function(entry){
          newComponent.styleProps[entry[0]] = _.cloneDeep(entry[1]);
        });
      }

      state.activeSlide.objects.push(newComponent);
    },

    deleteObject(state, id){
      this.commit('deleteIdFromGroups', id);
      this.commit('deleteIdFromAnimations', id);

      const index = this.state.activeSlide.objects.findIndex(
          o => o.id === id);

      if (index !== -1) {

        this.state.activeSlide.objects.map(function(obj){
          if (obj.linkedDots?.start[0] === index) {
            obj.linkedDots.start = [];
          }
          if (obj.linkedDots?.end[0] === index) {
            obj.linkedDots.end = [];
          }
        });

        this.state.activeSlide.objects.splice(index, 1);
      }
    },

    deleteIdFromAnimations(state, id){

      const slide = state.activeSlide;
      slide.animations.forEach(function(a){
        const i = a.indexOf(id);
        if (i !== -1) {
          a.splice(i, 1);
        }
      });

      // Чистим анимации от пустых массивов
      slide.animations = slide.animations.filter(a => !(Array.isArray(a) && (a.length === 0)));
    },

    setActiveTool(state, index){
      this.state.toolbar.currentActive = index;
    },

    saveHistory(state, from){
      return setTimeout(
          ()=> {
            this.commit('saveHistoryCommon', from);
          },
          20
      );
    },

    saveHistorySync(state, from){
      this.commit('saveHistoryCommon', from);

    },

    saveHistoryCommon(state, from){
      // Проверка на ноду
      if ((typeof window === 'undefined' || window === null)) {
        return;
      }
      if (this.history != null) {

        let dd;
        const copy = _.cloneDeepWith(
            state.presentation,
            function(val, key){
              if (key==='vueObj') {
                return {};
              }
              if (key==='dataURI') {
                return '';
              }
            });

        copy.timeStamp = Date.now();

        const stringifiedCopy = JSON.stringify(copy);

        // App.validateJson(copy)
        if (this.history[0].length > 50) {
          state.userActions.shift();
        }
        if ((this.history[0].last() != null) && (typeof this.history[0].last() === "string")) {
          dd = detailedDiff(JSON.parse(this.history[0].last()), copy);
        }
        this.history[0].push(stringifiedCopy);
        this.history[1] = [];

        this.state.historyAvailible.undo = this.history[0].length > 1;
        this.state.historyAvailible.redo = this.history[1].length > 0;

        this.commit('saveCopyToLocalStorage');

        this.commit('logUserAction', {
          from: 'store',
          action: 'saveHistory',
          time: Date.now(),
          payload: JSON.stringify(dd)
        });
        window.log('History saved: from ' + from);
      }

    },

    replaceHistory(state, from){
      const that = this;

      setTimeout(
          function(){
            if (that.history != null) {

              const copy = _.cloneDeepWith(
                  state.presentation,
                  function(val, key){
                    if (key==='vueObj') {
                      return {};
                    }
                    if (key==='dataURI') {
                      return '';
                    }
                  });

              copy.timeStamp = Date.now();

              const stringifiedCopy = JSON.stringify(copy);

              const App = GetFromWindow();
              App.validateJson(copy);

              const lastHistoryItemIndex = that.history[0].length - 1;
              if (that.history[0][lastHistoryItemIndex] != null) {
                that.history[0][lastHistoryItemIndex] = stringifiedCopy;
              }
              that.commit('saveCopyToLocalStorage');
              window.log('History replaced from ' + from);
            }
          },
          20
      );

    },

    saveCopyToLocalStorage(state){

      let previewsGenerator;
      const currentState = this.history[0].last();

      if (localStorage.getItem('developMode') !== 'true') {

        const {
          presentationId
        } = state;

        // Сохраняем последнюю актуальную версию в indexedDB
        CLS.setItem(presentationId, currentState);
        localStorage.setItem('savedToServer', 'false');
      }

      // Генерируем превьюхи
      (previewsGenerator = function() {

        const newPreviewsHtmls = [];
        const newVjsons = {
          slides: [],
          meta: {
            size: _.cloneDeep(state.presentation.size),
            animation: _.cloneDeep(state.presentation.animation),
            name: state.title,
            media: state.presentation.media,
            palette: state.presentation.theme.palette
          }
        };

        const animationColor = newVjsons.meta.animation.color;

        if (newVjsons.meta.animation.type === 'none') {
          newVjsons.meta.animation.duration = 0;
        }

        for (let slide of Array.from(state.presentation.slides)) {
          if ((slide.vueObj.$el != null) && (slide.bg.vueObj.$el != null)) {
            newPreviewsHtmls.push({
              slide: slide.vueObj.$el.innerHTML
              // bg: slide.bg.vueObj.$el.innerHTML
            });

            newVjsons.slides.push({
              objects: slide.objects.map(o => o.vueObj.getVjson()),
              bg: slide.bg.vueObj.getVjson(),
              animations: slide.animations
            });

          } else {
            setTimeout(
                function(){
                  previewsGenerator();
                },
                20
            );
            return;
          }
        }
        state.previewsHtmls = newPreviewsHtmls;
        state.vjsons = newVjsons;
      })();


    },

    checkPresentationRelevance(state){

      const {
        presentationId
      } = state;

      CLS.getItem(presentationId).then(res=> {

        const currentStateString = this.history[0].last();
        const lsStateString = res;

        if ((currentStateString == null) || (lsStateString == null)) {
          return;
        }

        const currentState = JSON.parse(currentStateString);
        const lsState = JSON.parse(lsStateString);

        const currentTimeStamp = currentState.timeStamp;
        const lsTimeStamp = lsState.timeStamp;

        if (lsTimeStamp > currentTimeStamp) {
          console.log('unrelevant presentation');
          state.presentation = lsState;
          this.commit('setActiveSlide', this.getters.currentActiveSlide);
          this.commit('saveHistory', 'restore presentation');
        }

      });
    },

    historyUndo(state){
      if (this.history?.[0].length > 0) {
        if (this.history[0].length > 1) {
          this.history[1].push(this.history[0].pop());
          state.presentation = JSON.parse(this.history[0][this.history[0].length - 1]);
          this.commit('saveCopyToLocalStorage');
          window.log('History undo');
          // Следующую строку не удалять!!!
          // Без неё теряется реактивность
          this.commit('setActiveSlide', this.getters.currentActiveSlide);
          this.state.historyAvailible.undo = this.history[0].length > 1;
          this.state.historyAvailible.redo = this.history[1].length > 0;
        } else {
          window.log('Cannot undo: nothing to undo');
        }
      }
    },

    historyRedo(state){
      if (this.history?.[1].length > 0) {
        this.history[0].push(this.history[1].pop());
        state.presentation = JSON.parse(this.history[0][this.history[0].length - 1]);
        this.commit('saveCopyToLocalStorage');
        window.log('History redo');
        // Следующую строку не удалять!!!
        // Без неё теряется реактивность
        this.commit('setActiveSlide', this.getters.currentActiveSlide);
        this.state.historyAvailible.undo = this.history[0].length > 1;
        this.state.historyAvailible.redo = this.history[1].length > 0;
      } else {
        window.log('Cannot redo: nothing to redo');
      }
    },

    saveEditingObjectsIds(state, ids){
      state.presentation.editingObjectsIds = ids;
      // @commit('replaceHistory', 'saveEditingObjectsIds')
    },

    group(state, req){

      const newGroup = [];
      const oldElems = [];

      state.activeSlide.groups.map(function(group, i){
        let targetArr;
        if (Array.from(req.groupIndexes).includes(i)) {
          targetArr = newGroup;
        } else {
          targetArr = oldElems;
        }
        targetArr.push(_.cloneDeep(group));
      });

      req.objectIndexes.map(function(index){
        newGroup.push(state.activeSlide.objects[index].id);
      });

      const res = [newGroup].concat(oldElems);

      state.activeSlide.groups = res;

      this.commit('saveHistory', 'group');

    },

    ungroup(state, groupId){

      const groupToDisband = _.pullAt(state.activeSlide.groups, groupId);

      groupToDisband[0].map(function(elem){
        if (elem instanceof Array) {
          state.activeSlide.groups.push(elem);
        }
      });

      this.commit('saveHistory', 'ungroup');

    },

    deleteIdFromGroups(state, id){
      const {
        groups
      } = this.state.activeSlide;

      var deleteIdFromGroup = function(arr, id){
        arr.forEach(function(elem, i, array){
          if (elem === id) {
            array.splice(i, 1);
            return;
          }
          if (elem instanceof Array) {
            deleteIdFromGroup(elem, id);
          }
        });
      };

      var clearGroup = function(arr, isRecursion){

        let before;
        const isRoot = !isRecursion;

        if (isRoot) {
          before = JSON.stringify(arr);
        }

        arr.forEach(function(elem, i, array){
          if (Array.isArray(elem)) {
            if (elem.length === 0) {
              array.splice(i, 1);
              return;
            }
            if (elem.length === 1) {
              if (isRoot && !Array.isArray(elem[0])) {
                array.splice(i, 1);
                return;
              } else {
                array[i] = elem[0];
              }
            } else {
              clearGroup(elem, true);
            }
          } else {
            if (isRoot) {
              array.splice(i, 1);
            }
          }
        });

        if (isRoot) {
          const after = JSON.stringify(arr);
          if (after !== before) {
            clearGroup(arr);
          }
        }

      };

      deleteIdFromGroup(groups, id);
      clearGroup(groups);

    },

    pasteSlide(state, options){
      let copyJson;
      const copy = localStorage.getItem('slideClipboard');

      try {
        copyJson = JSON.parse(copy);
      } catch (e) {
        return false;
      }

      if (copyJson && copyJson.isPresentation) {

        if (copyJson.schemaVersion !== state.presentation.schemaVersion) {
          console.log('Unsupported schema version');
          return;
        }

        const that = this;

        if (copyJson.presentationId !== state.presentationId) {
          // Подготавливаем новые медиа
          // и меняем id'шники на актуальные
          const newMedia = {};
          Object.keys(copyJson.media).forEach(m=> {

            let nextId;
            if (copyJson.slide.bg?.['background-image'] != null) {
              if (+copyJson.slide.bg['background-image'] === +m) {
                nextId = state.presentation.lastId;
                this.commit('incrementId');
                copyJson.slide.bg['background-image'] = nextId;
              }
            }

            copyJson.slide.objects.forEach(object=> {

              if (object.styleProps?.['background-image'] != null) {
                if (+object.styleProps['background-image'] === +m) {
                  nextId = state.presentation.lastId;
                  this.commit('incrementId');
                  object.styleProps['background-image'] = nextId;
                }
              }
            });
            return newMedia[nextId] = copyJson.media[m];
          });


          copyJson.media = newMedia;

          // Распихиваем медиа
          Object.keys(copyJson.media).forEach(function(m){
            Vue.set(
                state.presentation.media,
                m,
                copyJson.media[m]
            );
          });
        }

        // Новые idшники
        // Вычислить соответствие idшников

        copyJson.slide.active = false;

        const idsMap = {};

        let nextId = state.presentation.lastId;
        copyJson.slide.id = nextId;
        this.commit('incrementId');

        copyJson.slide.objects.forEach(object=> {
          nextId = state.presentation.lastId;
          this.commit('incrementId');
          idsMap[object.id] = nextId;
          object.id = nextId;
        });

        // Поменять idшники в связках
        copyJson.slide.objects.forEach(function(object){
          const dots = object.linkedDots;
          if (dots != null) {
            _.forEach(dots, function(dot){
              if (dot[0] != null) {
                dot[0] = idsMap[dot[0]];
              }
            });
          }
        });

        // Поменять idшники в группах
        copyJson.slide.groups = _.cloneDeepWith(
            copyJson.slide.groups,
            function(value){
              if (value instanceof Array) {
                return;
              }
              return idsMap[value];
            });

        state.presentation.slides.splice(
            options.afterIndex,
            0,
            copyJson.slide
        );

        this.commit('saveHistory', 'pasteSlide');
      }

    },

    pasteObjects(state, data){

      if (data.schemaVersion !== state.presentation.schemaVersion) {
        console.log('Unsupported schema version');
        return;
      }

      const that = this;

      if (data.presentationId !== state.presentationId) {
        // Подготавливаем новые медиа
        // и меняем id'шники на актуальные
        const newMedia = {};
        Object.keys(data.media).forEach(m=> {
          data.objects.forEach(object=> {
            if (object.styleProps?.['background-image'] != null) {
              if (+object.styleProps['background-image'] === +m) {
                const nextId = state.presentation.lastId;
                this.commit('incrementId');
                object.styleProps['background-image'] = nextId;
              }
            }
          });
          return newMedia[nextId] = data.media[m];
        });

        data.media = newMedia;

        // Распихиваем медиа
        Object.keys(data.media).forEach(function(m){
          Vue.set(
              state.presentation.media,
              m,
              data.media[m]
          );
        });
      }

      // Новые idшники
      // Вычислить соответствие idшников

      const idsMap = {};

      data.objects.forEach(object=> {
        const nextId = state.presentation.lastId;
        this.commit('incrementId');
        idsMap[object.id] = nextId;
        object.id = nextId;
      });

      // Поменять idшники в связках
      data.objects.forEach(function(object){
        const dots = object.linkedDots;
        if (dots != null) {
          _.forEach(dots, function(dot){
            if (dot[0] != null) {
              dot[0] = idsMap[dot[0]];
            }
          });
        }
      });

      // Поменять idшники в группах
      data.groups = _.cloneDeepWith(data.groups, function(value){
        if (value instanceof Array) {
          return;
        }
        return idsMap[value];
      });

      // Распихать объекты
      data.objects.forEach(function(object){
        object.shiftAfterCreation = true;
        state.activeSlide.objects.push(object);
      });

      // Обновить группы
      data.groups.forEach(function(group){
        state.activeSlide.groups.push(group);
      });
      this.commit('saveHistory', 'pasteObjects');
    },

    logUserAction(state, log){
      const str = JSON.stringify(_.cloneDeep(state.userActions));
      if (str.length > 200000) {
        state.userActions.shift();
      }
      state.userActions.push(log);
      state.sentry?.addBreadcrumb({
        category: 'userAction',
        message: JSON.stringify(log),
        level: 'info'
      });
      // console.log JSON.stringify(log)
      // window.log("#{log.action} #{log.from}")
    },

    previewColorTheme(state, themeId){

      if (state.themeCache == null) { state.themeCache = _.cloneDeep(state.presentation.theme); }
      this.commit('setColorTheme', themeId);
    },

    restoreColorTheme(state){
      if (state.themeCache) {
        this.commit('setColorTheme', state.themeCache);
        state.themeCache = null;
      }
    },

    changeColorTheme(state, themeId){

      state.themeCache = null;
      this.commit('setColorTheme', themeId);
      this.commit('saveHistory', 'changed theme');
    },

    setColorTheme(state, themeId){

      let theTheme;
      if (themeId === state.presentation.theme.id) {
        return;
      }

      const oldTheme = state.presentation.theme;

      if (themeId instanceof Object) {
        theTheme = themeId;
      } else {
        theTheme = state.themes[themeId];
      }

      const opts = theTheme.colorOptions;
      const checkPalette = function(obj, objKey, optsKey){
        if (obj[objKey].palette === opts[optsKey]) {
          obj[objKey].palette = opts[optsKey];
        }
      };


      state.presentation.slides.forEach(function(slide){
        checkPalette(
            slide.bg,
            'background-color',
            'slideBg'
        );

        slide.objects.forEach(function(o){

          const bc = 'background-color';
          const sc = 'stroke-color';

          if (o.type === 'table') {

            o.tableData.rows.forEach(function(r){

              r.cells.forEach(function(c){

                Object.keys(c.borders).forEach(function(b){
                  checkPalette(
                      c.borders[b],
                      'color',
                      'tableStroke'
                  );
                });

                if (c.style.fill) {
                  checkPalette(
                      c.style,
                      'fill',
                      'tableBg1'
                  );
                }

                if (c.text != null) {
                  if (c.text.ps != null) {
                    c.text.ps.forEach(function(p){
                      if (p.spans != null) {
                        p.spans.forEach(function(s){
                          checkPalette(
                              s.style,
                              'color',
                              'text'
                          );
                        });
                      }
                    });
                  }
                }

              });

            });

          } else {

            if (o.styleProps != null) {
              if (o.styleProps[bc] != null) {
                checkPalette(
                    o.styleProps,
                    bc,
                    'objectBg'
                );
              }

              if (o.styleProps[sc] != null) {
                checkPalette(
                    o.styleProps,
                    sc,
                    'objectStroke'
                );
              }
            }


            if (o.text != null) {
              if (o.text.ps != null) {
                o.text.ps.forEach(function(p){
                  if (p.spans != null) {
                    p.spans.forEach(function(s){
                      checkPalette(
                          s.style,
                          'color',
                          'text'
                      );
                    });
                  }
                });
              }
            }
          }

        });

      });

      state.presentation.theme = _.cloneDeep(theTheme);

    },

    addNewTheme(state, id){
      state.themes[id] = _.cloneDeep(state.themes['1']);
      state.themes[id].id = id;
    },

    resetAnimation(state){
      state.presentation.animation.type = 'slide';
      this.commit('replaceHistory', 'animation reset');
    },




    // ------------------------------------------------------------
    updateFlags(state, flag) {
      state.flags = {...state.flags, ...flag};
    },
    setThemes(state, themes) {
      state.themes = themes;
    },
  },
  getters: {
    getSlides(state){
      return state.presentation.slides;
    },
    currentActiveSlide(state){
      let res = false;
      if ((state.presentation == null) || (state.presentation.slides == null)) {
        return res;
      }
      state.presentation.slides.forEach(function(slide, index){
        if(slide.active===true) {
          res = index;
        }
      });
      return res;
    },
    blanks(state){
      const blanks = _.cloneDeep(state.blanks);
      blanks.p.spans[0].style.color.palette = state.presentation.theme.colorOptions.text;
      blanks.border.color.palette = state.presentation.theme.colorOptions.tableStroke;
      return blanks;
    }
  },
  actions: {},
})
