<template>
    <div id="app">
        <router-view v-if="error === null"/>
        <ErrorPage v-else :error="error"/>
    </div>
</template>

<script>
import ErrorPage from '@/components/Layouts/ErrorPage';

export default {
    name: 'App',
    components: { ErrorPage },
    data: () => ({
        error: null
    }),

    errorCaptured (err, vm, info) {
        // err: error trace
        // vm: component in which error occured
        // info: Vue specific error information such as lifecycle hooks, events etc.
        // TODO: Perform any custom logic or log to server
        // return false to stop the propagation of errors further to parent or global error handler
        console.log('ErrorPage', err);
        if (!!err) {
            this.error = err;
        }
    },
    created () {
        // eslint-disable-next-line no-return-assign
        this.$root.$on('error', err => this.error = err);
    }
};
</script>

<style type="text/css">
/* Base styles */
.relative {
    position: relative !important;
}

.disabled-btn {
    opacity: 0.3;
    cursor: not-allowed !important;
}

.workspace .canvas {
    background: #1D1F20;
}

.toolbar__group {
    background: #3E3F41;
}

.property__type_number .property__input {
    color: #B4C17B;
}

.prop-panel {
    background: #2A2C2D;
    color: #777777;
}

.rulers .ruler {
    background: #242626;
}

.rulers:after {
    background: #202122;
}

.scale-numbers__item {
    color: #979797;
}

.thumbs-bar {
    background: #202122;
}

.thumbs-bar .thumbs-bar__item__active, .thumbs-bar .thumbs-bar__item__active:hover {
    border-color: #10AFFF;
}

.thumbs-bar__mode__active {
    border-left-color: #10AFFF;
}

.prop-group__name_align {
    background: #202122;
}

.prop-group .prop-group__name {
    color: #FFFFFF;
}

.property .property__tabs:before {
    background-color: #333638;
}

.property__hash {
    color: #B4C17B;
}
</style>
