/* eslint-disable */
export default class CLS {
  static connectDB(cb) {
    const req = window.indexedDB.open("slidelab", 1);
    // eslint-disable-next-line no-unused-vars
    req.onerror = function(e) {};
    // console.log(e)
    req.onsuccess = function() {
      cb(req.result);
    };
    req.onupgradeneeded = function(e) {
      e.currentTarget.result.createObjectStore("presentations");
    };
  }

  static setItem(key, value) {
    const that = this;
    return new Promise(function(resolve, reject) {
      const cb = function(db) {
        const transaction = db.transaction(['presentations'], "readwrite");
        const store = transaction.objectStore('presentations');
        const request = store.put(value, key);
        request.onsuccess = function() {
          resolve(true);
        };
        request.onerror = function() {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(false);
        };
      };
      that.connectDB(cb);
    });
  }

  static getItem(key) {
    const that = this;
    return new Promise(function(resolve, reject) {
      const cb = function(db) {
        const transaction = db.transaction(['presentations'], "readonly");
        const store = transaction.objectStore('presentations');
        const request = store.get(key);
        request.onsuccess = function() {
          resolve(request.result);
        };
        request.onerror = function() {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(false);
        };
      };
      that.connectDB(cb);
    });
  }

  static removeItem(key) {
    const that = this;
    return new Promise(function(resolve, reject) {
      const cb = function(db) {
        const transaction = db.transaction(['presentations'], "readwrite");
        const store = transaction.objectStore('presentations');
        const request = store.delete(key);

        request.onsuccess = function() {
          resolve(true);
        };
        request.onerror = function() {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(false);
        };
      };
      that.connectDB(cb);
    });
  }
}
