import router from '@/router/index';
import { apiGet } from '@/models/Api';
import { UserSingleton } from '@/models/UserSingleton';

export default {
    state: {
        id: null,
        name: '',
        email: '',
        avatar: null,
        role: 0,
        token: '',
        
        verified: null,
        emailVerified: null,
        projectsUsed: null,
        projectsQuota: null,
        storageUsed: null,
        storageQuota: null,
        plan: null,
        templates: [],
        
        isAuthenticated: false,
        isAdmin: false
    },
    actions: {
        async INIT ({ dispatch }) {
            await dispatch('autoLogin');
            await dispatch('autoUserUpdate');
        },
        async autoLogin ({ commit }) {
            const token = UserSingleton.getToken();
            
            if (token !== null) {
                commit('setToken', token);
            } else {
                commit('setToken', null);
            }
            
            const user = await UserSingleton.getInstance();
            
            if (user !== null) {
                await commit('setUser', user);
            } else {
                await commit('resetUser');
            }
        },
        async autoUserUpdate ({ dispatch }) {
            if (this.state.user.token) {
                const res = await apiGet(`/api/v1/user`);
                
                if (res) {
                    await dispatch('setUser', res);
                }
            }
        },
        async logout ({ commit }, url: string = '/') {
            await apiGet('/api/v1/logout');
            
            commit('resetUser');
            commit('setToken', null);
            
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            
            UserSingleton.setToken(null);
            UserSingleton.setInstance(null);
            
            router.push(url);
        },
        setUser ({ dispatch }, user) {
            localStorage.setItem('user', user);
            UserSingleton.setInstance(null);
            
            dispatch('autoLogin');
        }
    },
    mutations: {
        setUser (state, user) {
            state.id = parseInt(user.id);
            state.name = user.name;
            state.email = user.email;
            state.avatar = user.avatar;
            state.role = parseInt(user.role);
            state.verified = user.verified;
            state.emailVerified = user.email_verified;
            state.projectsUsed = user.projects_used;
            state.projectsQuota = user.projects_quota;
            state.storageUsed = user.storage_used;
            state.storageQuota = user.storage_quota;
            state.plan = user.plan;
            state.templates = user.templates;
            state.isAdmin = Boolean(state.role);
        },
        setToken (state, token) {
            state.token = token;
            state.isAuthenticated = Boolean(token);
        },
        setUserParam (state, payload) {
            if (payload.key && payload.value) {
                state[payload.key] = payload.value;
            }
            
            this.dispatch('autoUserUpdate');
        },
        resetUser (state) {
            state.id = null;
            state.name = '';
            state.email = '';
            state.avatar = null;
            state.role = null;
            state.verified = null;
            state.emailVerified = null;
            state.projectsUsed = null;
            state.projectsQuota = null;
            state.storageUsed = null;
            state.storageQuota = null;
            state.plan = null;
            state.templates = [];
            state.isAdmin = false;
        }
    },
    getters: {}
};
