// ToDo: здесь пока закоментировал функии метрики...
/* eslint-disable */
export default {
  created() {
    this.analytics();
  },
  methods: {
    analytics() {
      // window.trackGAEventFn = function(action) {
      //   return gtag('event', action);
      // };
    },
    // eslint-disable-next-line no-unused-vars
    reachGoal(goal) {
      // yaCounter53617321.reachGoal(goal);
      // window.trackGAEventFn(goal);
    }
  }
};
