/* eslint-disable */

export default {
  'line': [
    'stroke-on',
    'stroke-color',
    'stroke-width',
    'stroke-cap',
    'stroke-type',
    'stroke-dash',
    'arrows',
    'opacity'
  ],
  'shape': [
    'fill-on',
    'stroke-on',
    'background-color',
    'stroke-color',
    'stroke-width',
    'stroke-type',
    'stroke-dash',
    'gradient',
    'color-mode',
    'adjust-to-text',
    'background-image',
    'image-opacity',
    'image-fill-mode',
    'image-scale',
    'lock-aspect',
    'opacity',
    'image-custom-scale',
    'image-shift-x',
    'image-shift-y'
  ]
};
