<template>
    <footer class="base-footer">
        <div class="footer-beta" v-if="isBeta">
            {{ $t('betaMsg') }}
        </div>
        <div class="cookies" v-if="showCookies">
            <span v-html="$t('cookiesMsg')"></span>
            <div class="btn cookies-btn" @click="setCookies">OK</div>
        </div>
        <div class="footer-links">
            <router-link to="/policy">
                {{ $t('privacyPolicy') }}
            </router-link>
            <a :href="`mailto:${supportEmail}`">
                {{ $t('contactUs') }}
            </a>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data () {
        return {
            isBeta: true,
            showCookies: !document.cookie.split('; ').includes('accepted_cookies=1'),
            supportEmail: 'info@slide-lab.ru'
        };
    },
    methods: {
        setCookies () {
            this.showCookies = false;
            document.cookie = 'accepted_cookies=1;expires=Mon, 01 Jan 2029 00:00:00 GMT;';
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/common.scss';

.base-footer {
    z-index: 1;
    display: block;
    font-size: 12px;
    position: relative;
    background: #2A2B2C;

    .footer-beta {
        position: relative;
        display: block;
        padding: 8px 15px;
        text-align: center;
        background: #e5e3ea;
        color: #779;
    }

    .footer-links {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 8px 15px;

        a {
            position: relative;
            display: block;
            margin: 0 10px;
            color: #fff;
        }
    }

    .cookies {
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        color: #fff;
        display: flex;
        padding-top: 0;
        position: fixed;
        flex-wrap: wrap;
        overflow: hidden;
        max-height: 120px;
        padding-bottom: 0;
        text-align: center;
        align-items: center;
        vertical-align: middle;
        box-sizing: border-box;
        justify-content: center;
        @include global_padding;
        transition: all .3s ease;
        background: $accent_color1;

        span {
            display: block;
            position: relative;
            margin: 8px 8px 8px 0px;
        }

        a {
            color: #fff;
            text-decoration: underline;
        }

        &-btn.btn {
            color: #fff;
            margin: 8px 0px;
            font-size: 12px;
            padding: 7px 17px;
            font-weight: bold;
            position: relative;
            display: inline-block;
            background: rgba(255, 255, 255, .1);
        }
    }
}
</style>

<i18n>
{
    "en": {
        "betaMsg": "SlideLab is in beta phase. We are working hard to make our service stable and bug free. Any kind of feedback is appreciated.",
        "privacyPolicy": "Privacy policy",
        "contactUs": "Contact us",
        "cookiesMsg": "We use cookies to keep you logged in and to provide seamless experience of the service. Please read our <a href='/policy' target='blank'>Privacy policy</a> for details."
    },
    "ru": {
        "betaMsg": "SlideLab находится в стадии бета-тестирования. Мы прилагаем все усилия, чтобы сделать наш сервис стабильным и свободным от ошибок. Любая обратная связь приветствуется.",
        "privacyPolicy": "Политика конфиденциальности",
        "contactUs": "Обратная связь",
        "cookiesMsg": "Мы используем файлы cookie, чтобы вы оставались в системе и обеспечивали бесперебойную работу службы. Подробнее в нашей <a href='/policy' target='blank'>Политике конфиденциальности</a>"
    }
}
</i18n>
