<template>
    <header class="header">
        <div class="container d-flex justify-content-between">
            <router-link class="logo" to="/">
                <img src="@/assets/images/logo.png" alt="SlideLab Logo"/>
            </router-link>
            <template v-if="!signUp && !signIn">
                <div class="header__nav">
                    <div class="header__nav-item">
                        <router-link @click.native="$root.reachGoal('clickLinkDocsFromDocsPage')" to="/docs">
                            {{ $t('learn') }}
                        </router-link>
                    </div>
                </div>
                <router-link
                    class="header__link"
                    @click.native="$root.reachGoal('clickLinkSignInFromDocsPage')"
                    :to="user.isAuthenticated ? '/account' : '/login'"
                >
                    {{ user.isAuthenticated ? $t('account') : $t('signIn') }}
                </router-link>

                <select class="locale-select" v-model="$root.$i18n.locale" @change="val => languageChanged(val)">
                    <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">
                        {{ locale }}
                    </option>
                </select>

            </template>
            <template v-else-if="signUp">
                <div class="header__right-block">
                    <router-link
                        class="header__link" to="/login"
                        @click="$root.reachGoal('clickLinkSignInFromLoginPage')"
                    >
                        <span class="btn__text">{{ $t('signIn') }}</span>
                    </router-link>
                </div>
            </template>
            <template v-else-if="signIn">
                <div class="header__right-block">
                    <router-link
                        class="header__link"
                        to="/login?mode=register"
                        @click="$root.reachGoal('clickLinkSignUpFromLoginPage')"
                    >
                        <span class="btn__text">{{ $t('signUp') }}</span>
                    </router-link>
                </div>
            </template>
        </div>
    </header>
</template>

<script>
import i18n from '@/mixins/i18n';

export default {
    name: 'BaseHeader',
    mixins: [i18n],
    props: {
        signUp: {
            type: Boolean,
            default: false
        },
        signIn: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            user: this.$store.state.user
        };
    }
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/rfs.scss';
// ToDo: перенести все стили основного хедера сюда
.header {
    z-index: 100;

    .header__nav-item a {
        opacity: .5;
        display: block;
        color: #3a3b41;
        padding: 3px 0;
        font-size: 11px;
        font-weight: 700;
        position: relative;
        letter-spacing: 1px;
        text-decoration: none;
        transition: all .3s ease;
        text-transform: uppercase;
        backface-visibility: hidden;
        font-family: Montserrat, sans-serif;
        border-bottom: 2px solid transparent;
    }

    .header__link {
        white-space: pre-line;
        font-family: Montserrat, sans-serif;
    }

    .locale-select {
        border: none;
        opacity: 0.8;
        color: #2d2d2d;
        margin-left: 8px;
        font-weight: 600;
        margin-bottom: 2px;
        letter-spacing: 1px;
        @include font-size(15px);
        text-transform: capitalize;
        font-family: Montserrat, sans-serif;

        // Select styles...
        outline: 0;
        text-indent: 1px;
        -moz-appearance: none;
        -webkit-appearance: none;
        &::-ms-expand {
            display: none;
        }
    }
}
</style>

<i18n>
{
    "en": {
        "signIn": "Sign in",
        "account": "Account",
        "signUp": "Sign up",
        "learn": "Learn",
        "support": "Support"
    },
    "ru": {
        "signIn": "Войти",
        "account": "Аккаунт",
        "signUp": "Регистрация",
        "learn": "Документация",
        "support": "Помощь"
    }
}
</i18n>
