/* eslint-disable */
import MSR from './MixinStylesReference';

export default {
    'line': MSR.line,
  'rectangle': MSR.shape.concat('corner-radius'),
  'rhombus': MSR.shape,
  'triangle': MSR.shape.concat('top-offset'),
  'parallelogram': MSR.shape.concat('parallelogram-angle'),
  'chevron': MSR.shape.concat('chevron-angle', 'chevron-first'),
  'arrow': MSR.shape.concat('arrow-angle', 'arrow-direction', 'arrow-thickness'),
  'circle': MSR.shape
};
