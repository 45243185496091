/* eslint-disable */

import Vue from 'vue';
import VueRouter from 'vue-router';
import auth from './middleware/auth';
import Home from '../views/Home.vue';
import admin from './middleware/admin';
import goToPage from './middleware/goToPage';
import store from '@/store';
import i18n from '@/i18n';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        // @FixMe: разобраться что не так с подключаемыми дефолтными файлами в scss, если данная страница грузиться отдельно слитают стили на других страницах
        // component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: {
            middleware: [goToPage],
            title: i18n.t('homeTitle'),
            metaTags: [
                { property: 'og:type', content: 'article' },
                { property: 'og:site_name', content: 'SlideLab' },
                { property: 'og:title', content: i18n.t('homeTitle') },
                { property: 'og:url', content: 'https://slide-lab.ru' },
                { property: 'og:image', content: 'https://slide-lab.ru/og-image.png' },
                { property: 'description', content: i18n.t('homeDescription') },
                { property: 'og:description', content: i18n.t('homeDescription') },
            ]
        }
    },
    {
        path: '/docs*',
        name: 'Docs',
        component: () => import(/* webpackChunkName: "docs" */ '../views/Docs.vue'),
        meta: {
            middleware: [goToPage],
            title: i18n.t('docsTitle'),
            metaTags: [
                { property: 'og:type', content: 'article' },
                { property: 'og:site_name', content: 'SlideLab' },
                { property: 'og:title', content: i18n.t('docsTitle') },
                { property: 'og:url', content: 'https://slide-lab.ru' },
                { property: 'og:image', content: 'https://slide-lab.ru/og-image.png' },
                { property: 'description', content: i18n.t('homeDescription') },
                { property: 'og:description', content: i18n.t('homeDescription') },
            ]
        }
    },
    {
        path: '/policy',
        name: 'Policy',
        component: () => import(/* webpackChunkName: "policy" */ '../views/Policy.vue'),
        meta: { middleware: [goToPage], title: i18n.t('policyTitle') }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: { middleware: [goToPage], title: i18n.t('loginTitle') }
    },
    {
        path: '/account',
        name: 'Account',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
        meta: { middleware: [auth, goToPage], title: i18n.t('accountTitle') }
    },
    {
        path: '/manager',
        name: 'Manager',
        component: () => import(/* webpackChunkName: "account" */ '../views/Manager.vue'),
        meta: { middleware: [auth, admin, goToPage], title: i18n.t('managerTitle') }
    },
    {
        path: '/editor',
        name: 'Editor',
        component: () => import(/* webpackChunkName: "editor" */ '../views/Editor.vue'),
        meta: { middleware: [auth, goToPage], title: i18n.t('editorTitle') }
    },
    {
        path: '/share',
        name: 'Share',
        component: () => import(/* webpackChunkName: "viewer" */ '../views/Viewer.vue'),
        meta: { middleware: [goToPage], title: i18n.t('shareTitle') }
    },
    {
        path: '/presentation-search',
        name: 'Share',
        component: () => import(/* webpackChunkName: "PresentationSearch" */ '../views/PresentationSearch.vue'),
        meta: {
            middleware: [goToPage],
            title: i18n.t('presentationSearchTitle'),
            metaTags: [
                { property: 'og:type', content: 'article' },
                { property: 'og:site_name', content: 'SlideLab' },
                { property: 'og:title', content: i18n.t('presentationSearchTitle') },
                { property: 'description', content: i18n.t('presentationSearchDescription') },
                { property: 'og:description', content: i18n.t('presentationSearchDescription') },
                { property: 'og:url', content: 'https://slide-lab.ru/presentation-search' },
                { property: 'og:image', content: 'https://slide-lab.ru/og-image.png' },
            ]
        }
    },
    {
        path: '/presentation-preview/:id',
        name: 'Presentaion Preview',
        component: () => import(/* webpackChunkName: "PresentationPreview" */ '../views/PresentationPreview.vue'),
        meta: (route) => ({
            middleware: [goToPage],
            title: i18n.t('presentationPreviewTitle'),
            metaTags: [
                { property: 'og:type', content: 'article' },
                { property: 'og:site_name', content: 'SlideLab' },
                { property: 'og:title', content: i18n.t('presentationPreviewTitle') },
                { property: 'description', content: i18n.t('presentationPreviewDescription') },
                { property: 'og:description', content: i18n.t('presentationPreviewDescription') },
                { property: 'og:url', content: `https://slide-lab.ru/presentation-preview/` + route?.params?.id },
                { property: 'og:image', content: 'https://slide-lab.ru/og-image.png' },
            ]
        })
    },
    
    {
        path: '/presentation-embed/:id/:height',
        name: ' Presentaion Embed',
        component: () => import(/* webpackChunkName: "PresentationEmbed" */ '../views/PresentationEmbed.vue'),
    },
    { path: '*', beforeEnter: (to, from, next) => { next('/404') } },
    {
        path: '/404',
        name: '404',
        component: () => import(/* webpackChunkName: "errors" */ '../views/PageNotFound.vue'),
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
    },
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory (context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;
    
    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
    
    if (to.meta?.metaTags) {
        to.meta.metaTags
            .map(tagDef => {
                const tag = document.createElement('meta');
                
                Object.keys(tagDef).forEach(key => {
                    tag.setAttribute(key, tagDef[key]);
                });
                
                tag.setAttribute('data-vue-router-controlled', '');
                
                return tag;
            })
            .forEach(tag => document.head.appendChild(tag));
    }
    
    if (to.meta?.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
        
        const context = {
            to,
            from,
            next,
            store,
            router,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);
        
        return middleware[0]({ ...context, next: nextMiddleware });
    }
    
    return next();
});

router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta?.title || 'SlideLab';
    });
});

export default router;
